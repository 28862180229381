<template>
  <div class="entire-container">
    <v-card
      class="mx-auto rounded-xl mb-3 pt-4"
      ref="pkgCard"
      max-width="300"
      :color="offerHeaderColors[colorIndex % offerHeaderColors.length]"
      elevation="0"
      v-on="isOfferTileClickable ? { click: () => selectPackage(packageData) } : {}"
    >
      <v-card flat color="transparent" class="items-container">
        <v-img contain height="110" max-height="110" width="500" :src="image(packageData)"> </v-img>
        <v-row class="text-center" align="start" justify="center">
          <v-col align="start">
            <v-row align="center" justify="center">
              <span id="package-display-name" class="speed">
                {{ displayName(packageData) }}<TooltipDebug :item="packageData" />
              </span>
            </v-row>
            <v-row align="center" justify="center">
              <sup id="package-speed-units" class="mbps" align="center" justify="center">{{ speedUnits(packageData) }}</sup>
            </v-row>
            <v-row align="center" justify="center">
              <div class="underline" align="center" justify="center"></div>
            </v-row>
            <v-row align="center" justify="center">
              <span id="package-price" class="price">${{ priceParts(packageData)[0] }}/mo</span>
            </v-row>
            <v-row v-if="packageData['Marketing Text 1']" class="marketing-text">
              <span class="description" v-html="packageData['Marketing Text 1']"></span>
            </v-row>
            <v-row v-if="packageData['Marketing Text 2']" class="marketing-text">
              <span class="description" v-html="packageData['Marketing Text 2']"></span>
            </v-row>
            <v-row v-if="packageData['Marketing Text 3']" class="marketing-text">
              <span class="description" v-html="packageData['Marketing Text 3']"></span>
            </v-row>
            <v-row v-if="packageData['Marketing Text 4']" class="marketing-text">
              <span class="description" v-html="packageData['Marketing Text 4']"></span>
            </v-row>
            <v-row v-if="packageData['Marketing Text 5']" class="marketing-text">
              <span class="description" v-html="packageData['Marketing Text 5']"></span>
            </v-row>
            <v-row v-if="packageData['Marketing Text 6']" class="marketing-text">
              <span class="description" v-html="packageData['Marketing Text 6']"></span>
            </v-row>
            <v-row v-if="packageData['Marketing Text 7']" class="marketing-text">
              <span class="description" v-html="packageData['Marketing Text 7']"></span>
            </v-row>
            <v-row v-if="packageData['Marketing Text 8']" class="marketing-text">
              <span class="description" v-html="packageData['Marketing Text 8']"></span>
            </v-row>
            <v-row v-if="packageData['Marketing Text 9']" class="marketing-text">
              <span class="description" v-html="packageData['Marketing Text 9']"></span>
            </v-row>
          </v-col>
        </v-row>
        <v-row align="end" justify="center">
          <v-btn
            x-large
            rounded
            class="button-special"
            elevation="0"
            v-on="!isOfferTileClickable ? { click: () => selectPackage(packageData) } : {}"
          >
            <v-btn
              elevation="0"
              large
              rounded
              dark
              :color="offerHeaderColors[colorIndex % offerHeaderColors.length]"
              v-html="offerButtonText"
            >
            </v-btn>
          </v-btn>
        </v-row>
      </v-card>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import useUiConfig from '@/components/shared/useUiConfig'
import usePackage from './usePackage'
import { Package } from '@adg/catalog/src/modules/Catalog'
import TooltipDebug from '@/components/shared/tooltip/TooltipDebug.vue'
import $store from '@/store'

export default defineComponent({
  name: 'PackageContained',
  props: {
    pkgHeight: Number,
    minWidth: Number,
    colorIndex: Number,
    packageData: {
      type: Object as () => Package,
      required: true
    }
    //    packageData: {
    //      Name: String,
    //      MonthlyPrice: String,
    //      Products: [{ name: String, speed: String }]
    //    }
  },
  components: {
    TooltipDebug
  },
  setup(props, { emit, refs, root: { $vuetify } }) {
    const { selectPackage, offerButtonText, priceParts, speedUnits, displayName, image, isOfferTileClickable } = usePackage(
      $store,
      $vuetify,
      emit
    )
    const { offerHeaderColors, offerButtonColors } = useUiConfig($store)

    return {
      displayName,
      speedUnits,
      selectPackage,
      priceParts,
      image,
      offerHeaderColors,
      offerButtonColors,
      offerButtonText,
      isOfferTileClickable
    }
  }
})
</script>

<style scoped>
.packageName {
  min-height: 115px;
}
.packageName-small {
  min-height: 80px;
}

.price {
  font-size: 36px;
  color: white;
}
.dollar {
  font-size: 38px;
  top: -0.9em;
  font-weight: bold;
  margin-left: -4px;
}
.cents {
  font-size: 24px;
  font-weight: bold;
  vertical-align: top;
  line-height: 0px;
}
.duration {
  font-size: 12px;
  vertical-align: top;
  line-height: 20px;
  margin-left: -30px;
}
.package-title {
  font-size: 28px;
}
.v-application .packageHeder {
  background-color: purple;
  color: red;
}

.v-sheet.v-card {
  border-radius: 15px;
}
.mbps {
  font-size: 18px;
  color: white;
}
.speed {
  font-size: 64px;
  font-weight: bold;
  color: white;
}
.underline {
  width: 100px;
  border-bottom: thin solid white;
  height: 20px;
}
.description {
  font-size: 16px;
  color: white;
  text-align: left;
}
.button-special {
  top: 25px;
  max-width: 105px;
}
.marketing-text {
  flex-wrap: wrap;
  justify-content: left;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 40px;
  margin-right: 40px;
}
.entire-container {
  display: flex;
  height: 100%;
}
.items-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
