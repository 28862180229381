
<span v-if="validAccountField(field, shopper)">
  <div id="account-field-hint" class="account-field-hint" v-if="field.hint" v-html="field.hint"></div>
  <v-text-field
    v-if="field.type === 'textField'"
    v-model="accountFieldModel"
    :error="!accountFieldModel && field.showInitialError"
    :label="field.label"
    :rules="rules"
    :maxlength="field.maxLength ? field.maxLength : Infinity"
    v-mask="mask"
    v-bind="textFieldProps"
  >
    <template v-if="field.label || field.labelMacro" v-slot:label>
      <span
        @click.stop
        class="text-box-label"
        v-html="field.labelMacro ? uiMacroParser(shopper, field.labelMacro, true) : field.label"
      ></span>
    </template>
  </v-text-field>

  <v-radio-group
    :rules="rules"
    v-if="field.type === 'radioGroup' && field.options"
    v-model="accountFieldModel"
    :label="field.label"
  >
    <div v-for="(option, index) in field.options" :key="index">
      <v-radio :label="option.label" :value="option.value">
        <template v-if="option.labelMacro" v-slot:label>
          <span @click.stop class="radio-option-label" v-html="uiMacroParser(shopper, option.labelMacro, true)"></span>
        </template>
      </v-radio>
    </div>
    <template v-if="field.labelMacro" v-slot:label>
      <span @click.stop class="radio-group-label" v-html="uiMacroParser(shopper, field.labelMacro, true)"></span>
    </template>
  </v-radio-group>

  <v-text-field
    v-else-if="field.type === 'date'"
    v-model="dobFieldModel"
    :label="field.label"
    :rules="rules"
    :maxlength="field.maxLength ? field.maxLength : Infinity"
    v-mask="mask"
    v-bind="textFieldProps"
    autocomplete="bday"
  >
    <template v-if="field.labelMacro" v-slot:label>
      <span @click.stop class="date-textfield-label" v-html="uiMacroParser(shopper, field.labelMacro, true)"></span>
    </template>
  </v-text-field>

  <!-- i know onkeypress is ugly; v-mask does not work on v-textarea - this will block characters needed for html injection from being input -->
  <v-textarea
    v-else-if="field.type === 'textArea'"
    :label="field.label"
    v-model="accountFieldModel"
    :rules="rules"
    onkeypress="return /[^<>&]/i.test(event.key)"
    solo
    no-resize
    :maxlength="field.maxLength ? field.maxLength : Infinity"
  >
    <template v-if="field.labelMacro" v-slot:label>
      <span @click.stop class="textarea-field-label" v-html="uiMacroParser(shopper, field.labelMacro, true)"></span>
    </template>
  </v-textarea>

  <v-checkbox
    v-else-if="field.type === 'checkbox'"
    v-model="accountFieldBoolean"
    :label="field.label"
    dense
    class="noMargin"
    style="padding-top: 0px; padding-bottom: 0px"
    :rules="rules"
  >
    <template v-if="field.labelMacro" v-slot:label>
      <span @click.stop class="checkbox-field-label" v-html="uiMacroParser(shopper, field.labelMacro, true)"></span>
    </template>
  </v-checkbox>

  <v-select
    v-if="field.type === 'dropdown'"
    :items="field.dropdownOptions"
    v-model="accountFieldModel"
    :label="field.label"
    outlined
    :background-color="field.backgroundColor ? field.backgroundColor : 'white'"
    :rules="rules"
  >
    <template v-if="field.labelMacro" v-slot:label>
      <span @click.stop class="dropdown-label" v-html="uiMacroParser(shopper, field.labelMacro, true)"></span>
    </template>
  </v-select>

  <v-list-item v-if="field.type === 'terms'" class="pl-0 pb-3">
    <template v-slot:default="{ active, toggle }">
      <v-list-item-action style="align-self: start">
        <v-checkbox v-model="termsModel" :rules="rules" class="blackCheckbox" />
      </v-list-item-action>
      <v-list-item-content class="pl-0">
        <!-- i know a span in a span is ugly, but otherwise the tooltip drops to the line below -->
        <span>
          <span
            @click.stop
            style="color: rgba(0, 0, 0, 0.6)"
            v-html="field.labelMacro ? uiMacroParser(shopper, field.labelMacro, true) : field.label"
          ></span>
        </span>
        <span id="terms-checkbox-required" v-if="!termsModel" class="pl-2" style="color: red; font-size: 12px"> Required </span>
      </v-list-item-content>
    </template>
  </v-list-item>
  <span
    v-else-if="field.type === 'text'"
    v-html="field.labelMacro ? uiMacroParser(shopper, field.labelMacro, true) : field.label"
    id="custom-text"
  ></span>
  <div v-else-if="field.type === 'addressSeniority'">
    <AddressSeniority
      :getAddress="GET_PREVIOUS_ADDRESS_SUGGESTIONS"
      :fetchAddress="FETCH_PREVIOUS_ADDRESS_SUGGESTIONS"
      setAddress="updateShopperPreviousAddress"
      :checkboxLabel="addressSeniorityLabel"
      defaultCheckedState
      :rules="rules"
    />
  </div>
  <div v-else-if="field.type === 'differentBilling'" class="pb-0">
    <AddressSeniority
      :getAddress="GET_BILLING_ADDRESS_SUGGESTIONS"
      :fetchAddress="FETCH_BILLING_ADDRESS_SUGGESTIONS"
      setAddress="updateShopperBillingAddress"
      addressLabel="Enter your billing address"
      :checkboxLabel="differentBillingLabel"
      showAddressOnCheck
      :rules="rules"
    />
  </div>
  <div v-else-if="field.type === 'image'">
    <span v-html="field.labelMacro ? uiMacroParser(shopper, field.labelMacro, true) : field.label"></span>
    <image-uploader
      :capture="true"
      :debug="0"
      :maxWidth="500"
      :quality="1.0"
      :scaleRatio="1.0"
      :autoRotate="true"
      outputFormat="jpg"
      :preview="true"
      accept="image/*"
      @input="onFileSelected"
      :rules="rules"
    ></image-uploader>
  </div>
  <div v-else-if="field.type === 'questions'">
    <SecurityQuestions :field="field" :rules="rules" />
  </div>
  <div v-else-if="field.type === 'referral'">
    <Referrals :field="field" :rules="rules" />
  </div>
</span>
