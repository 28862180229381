
<div class="entire-container">
  <v-card
    class="mx-auto rounded-xl mb-3 pt-4"
    ref="pkgCard"
    max-width="300"
    :color="offerHeaderColors[colorIndex % offerHeaderColors.length]"
    elevation="0"
    v-on="isOfferTileClickable ? { click: () => selectPackage(packageData) } : {}"
  >
    <v-card flat color="transparent" class="items-container">
      <v-img contain height="110" max-height="110" width="500" :src="image(packageData)"> </v-img>
      <v-row class="text-center" align="start" justify="center">
        <v-col align="start">
          <v-row align="center" justify="center">
            <span id="package-display-name" class="speed">
              {{ displayName(packageData) }}<TooltipDebug :item="packageData" />
            </span>
          </v-row>
          <v-row align="center" justify="center">
            <sup id="package-speed-units" class="mbps" align="center" justify="center">{{ speedUnits(packageData) }}</sup>
          </v-row>
          <v-row align="center" justify="center">
            <div class="underline" align="center" justify="center"></div>
          </v-row>
          <v-row align="center" justify="center">
            <span id="package-price" class="price">${{ priceParts(packageData)[0] }}/mo</span>
          </v-row>
          <v-row v-if="packageData['Marketing Text 1']" class="marketing-text">
            <span class="description" v-html="packageData['Marketing Text 1']"></span>
          </v-row>
          <v-row v-if="packageData['Marketing Text 2']" class="marketing-text">
            <span class="description" v-html="packageData['Marketing Text 2']"></span>
          </v-row>
          <v-row v-if="packageData['Marketing Text 3']" class="marketing-text">
            <span class="description" v-html="packageData['Marketing Text 3']"></span>
          </v-row>
          <v-row v-if="packageData['Marketing Text 4']" class="marketing-text">
            <span class="description" v-html="packageData['Marketing Text 4']"></span>
          </v-row>
          <v-row v-if="packageData['Marketing Text 5']" class="marketing-text">
            <span class="description" v-html="packageData['Marketing Text 5']"></span>
          </v-row>
          <v-row v-if="packageData['Marketing Text 6']" class="marketing-text">
            <span class="description" v-html="packageData['Marketing Text 6']"></span>
          </v-row>
          <v-row v-if="packageData['Marketing Text 7']" class="marketing-text">
            <span class="description" v-html="packageData['Marketing Text 7']"></span>
          </v-row>
          <v-row v-if="packageData['Marketing Text 8']" class="marketing-text">
            <span class="description" v-html="packageData['Marketing Text 8']"></span>
          </v-row>
          <v-row v-if="packageData['Marketing Text 9']" class="marketing-text">
            <span class="description" v-html="packageData['Marketing Text 9']"></span>
          </v-row>
        </v-col>
      </v-row>
      <v-row align="end" justify="center">
        <v-btn
          x-large
          rounded
          class="button-special"
          elevation="0"
          v-on="!isOfferTileClickable ? { click: () => selectPackage(packageData) } : {}"
        >
          <v-btn
            elevation="0"
            large
            rounded
            dark
            :color="offerHeaderColors[colorIndex % offerHeaderColors.length]"
            v-html="offerButtonText"
          >
          </v-btn>
        </v-btn>
      </v-row>
    </v-card>
  </v-card>
</div>
