
<div class="packages-container">
  <template v-for="(p, i) in filterPackages">
    <div :key="i" :class="packageComponent" class="pa-2" :id="lobString + (i + 1)">
      <PackageNew
        :packageData="p"
        :colorIndex="i"
        @packageSelected="packageSelected"
        :minWidth="225"
        :filterPackagesCount="filterPackageCount"
        :displayNameTextColor="displayNameTextColor(i)"
        :hasUnderPriceText="hasUnderPriceText"
      ></PackageNew>
    </div>
  </template>
</div>
