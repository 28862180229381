// interface spec for Catalog.ts
// This is the main interface spec used as input to Quicktype to generate ../CatalogConvert.ts
// Edit this file to chage the interface, and use the Make process in utils/catalog-utils/cat to
// remake ../CatalogConvert.ts from this file.

import { PassThrough } from 'stream'
import { MonthlyPrice } from '../../v3/interface/icatalog'

export type CatalogStatus = 'SUCCESS' | 'FAILURE' | 'DUBIOUS'
export interface CatalogKind {
  kind: undefined | 'hydrated' | 'nascent' | 'empty'
}

export type ChargeTypeKey = 'Monthly Price' | 'OTC'

export type StringOrNumber = string | number

export type ItemPrice = StringOrNumber

export interface Catalog extends CatalogKind {
  kind: 'hydrated' | 'empty'
  ChannelGroups?: ChannelGroups
  Config: Config[]
  Groups?: any[]
  LocMap: LOCMap[]
  MarketingText: MarketingText[]
  Packages: Package[]
  products?: Product[]
  features?: Feature[]
  upgrades?: Upgrade[]
  promos?: Promo[]
  equipment?: Equipment[]
  RefData: RefData
  billingCodes?: BillingCode[]
  tagMap: TagMap[]
  tags: Tag[]
  fees?: Fee[]
  tagWindows?: TagWindow[]
  version?: number
}

export interface CatalogV3 extends CatalogKind {
  kind: undefined
  ChannelGroups?: ChannelGroups
  Config: Config[]
  Groups?: any[]
  LocMap: LOCMap[]
  MarketingText: MarketingText[]
  Packages: Package[]
  products?: Product[]
  upgrades?: Upgrade[]
  promos?: Promo[]
  equipment?: Equipment[]
  RefData: RefData
  billingCodes?: BillingCode[]
  tagMap: TagMap[]
  tags: Tag[]
  fees?: Fee[]
  tagWindows?: TagWindow[]
  version?: number
}

export function newCatalog(): Catalog {
  return {
    kind: 'empty',
    Config: [],
    LocMap: [],
    MarketingText: [],
    Packages: [],
    RefData: {},
    tagMap: [],
    tags: []
  }
}

export type ConfigItem = 'Config'
export type LocationMappingItem = 'LocationMapping'
export type MarketingTextItem = 'MarketingText'
export type PackageItem = 'Package'
export type BillingCodeItem = 'BillingCode'
export type FeeItem = 'Fee'
export type FeeGroupItem = 'FeeGroup'
export type ProductItem = 'Product'
export type EquipmentItem = 'Equipment'
export type CountedEquipmentGroupItem = 'CountedEquipmentGroup'
export type PromoItem = 'Promo'
export type UpgradeItem = 'Upgrade'
export type TagMapItem = 'TagMap'
export type TagWindowItem = 'TagWindow'
export type TagItem = 'Tag'
export type CartTotalItem = 'CartTotal'
export type FeatureItem = 'Feature'
export type BroadbandLabelItem = 'BroadbandLabel'
export type GroupItem = 'Group'

export type ItemType =
  | ConfigItem
  | LocationMappingItem
  | MarketingTextItem
  | PackageItem
  | BillingCodeItem
  | FeeItem
  | FeeGroupItem
  | ProductItem
  | EquipmentItem
  | CountedEquipmentGroupItem
  | PromoItem
  | UpgradeItem
  | TagMapItem
  | TagWindowItem
  | TagItem
  | CartTotalItem
  | FeatureItem
  | GroupItem
  | BroadbandLabelItem

export interface BaseItem {
  Name: string
  Tag?: string
  'Display Name'?: string
  itemType: ItemType
  position?: number // not in catalog, populated by ga4 based upon position displayed and really only for packages
}
export interface Item extends BaseItem {
  priceIncluded?: boolean
  qty?: number
  included?: string | boolean
  required?: string | boolean
  excluded?: string | boolean
  Rank?: number | string
  Disclaimer?: string
  Description?: string
  expandable?: boolean
  // 'Location Type'?: LocationType
  // Locations?: Location[]
}

export interface PricedItem extends Item {
  PricePrefix?: string
  'Monthly Price'?: ItemPrice
  OTC?: ItemPrice
  //CalculatedPrice?: Fee[]
  StartMonth?: number
  EndMonth?: number
}

// export interface hasFees {
//   Fees?: Fee[]
// }

export interface FeedItem extends PricedItem {
  Fees?: Fee[]
  'Billing Codes'?: BillingCode[]
  PriceIntoParent?: string | boolean
  Features?: Feature[]
}

export interface Feature extends BaseItem {
  Description?: string
  AlternativeDescription?: string
  Image?: string
  Rank?: number | string
  FeatureType:
    | 'Button'
    | 'Text'
    | 'Image'
    | 'Checkbox'
    | 'Latency'
    | 'LabelInternetCategory'
    | 'LabelContractLength'
    | 'LabelDiscountBundle'
    | string
  Class?: string
  RowClass?: string
  ExternalLink?: string
  Id?: string
  Features?: Feature[]
}

export interface BroadbandLabel extends BaseItem {
  Name: string
  UniquePlanId?: string
  ProviderName: string
  ServicePlanNameOrSpeedTier: string
  FixedOrMobile: 'Fixed' | 'Mobile'
  HasIntroPrice?: boolean
  IntroPrice?: string | number
  IntroPeriod?: string
  MonthlyPrice: string | number
  ContractRequired?: boolean
  ContractLength?: string
  ContractTermsUrl?: string
  ContractFee?: string
  EarlyTerminationFee?: string | number
  GovernmentTaxes?: string | number
  DiscountsText?: string
  DiscountsUrl?: string
  ParticipatesInACP: boolean
  DownloadSpeed: string
  UploadSpeed: string
  LatencySpeed: string
  IncludedData: string
  AdditionalData?: string
  AdditionalDataPrice?: string
  NetworkManagementPolicyUrl: string
  PrivacyPolicyUrl: string
  CustomerSupportUrl: string
  CustomerSupportPhoneNumber: string
  CustomerSupportEmailAddress: string
  MonthlyFeeName1?: string
  MonthlyFeePrice1?: string
  OneTimeFeeName1?: string
  OneTimeFeePrice1?: string
  MonthlyFeeName2?: string
  MonthlyFeePrice2?: string
  OneTimeFeeName2?: string
  OneTimeFeePrice2?: string
  MonthlyFeeName3?: string
  MonthlyFeePrice3?: string
  OneTimeFeeName3?: string
  OneTimeFeePrice3?: string
  MonthlyFeeName4?: string
  MonthlyFeePrice4?: string
  OneTimeFeeName4?: string
  OneTimeFeePrice4?: string
  MonthlyFeeName5?: string
  MonthlyFeePrice5?: string
  OneTimeFeeName5?: string
  OneTimeFeePrice5?: string
  MonthlyFeeName6?: string
  MonthlyFeePrice6?: string
  OneTimeFeeName6?: string
  OneTimeFeePrice6?: string
}

// export type BroadbandLabelFeatureType = 'Latency' | 'UploadSpeed' | 'DownloadSpeed'

export interface FeaturePackagePiece {
  type: string
  packageFeatureItems: FeaturePackagePieceItem[]
  class?: string
}

export interface FeaturePackagePieceItem {
  childName: string
  parentName: string
  class?: string
}
//TODO: FEATURE add other parameters

export function isTaggedSheetName(sheetName: SheetName): sheetName is TaggedSheetName {
  return taggedSheetNames.includes(sheetName as TaggedSheetName)
}

export type TaggedSheetName =
  | 'Packages'
  | 'Products'
  | 'Promos'
  | 'Fees'
  | 'Equipment'
  | 'Upgrades'
  | 'Channel Groups'
  | 'Groups'
  | 'Config'
  | 'Marketing Text'
  | 'Billing Codes'
  | 'Features'
  | 'BroadbandLabels'

export type ReferenceSheetName = 'Reference Data' | 'Location Mapping' | 'Tags' | 'TagMap' | 'TagWindows'

export type SheetName = TaggedSheetName | ReferenceSheetName

export const taggedSheetNames: TaggedSheetName[] = [
  'Packages',
  'Products',
  'Promos',
  'Fees',
  'Equipment',
  'Upgrades',
  'Channel Groups',
  'Groups',
  'Config',
  'Marketing Text',
  'Billing Codes',
  'Features',
  'BroadbandLabels'
]

export interface ChannelGroups {
  'Limited Basic'?: string[]
  'Value Pack'?: string[]
  'Expanded Basic'?: string[]
  'Digital Preferred'?: string[]
  'Spanish Tier'?: string[]
  'Sports Pak'?: string[]
  itemType?: string
}

export interface Config extends Item {
  'Config Value'?: any
  Key?: string
  Type?: ProductType
}

export type LocationType = string
export type Location = string
export type ProductType = string
//export type ProductType = 'Internet' | 'TV' | 'Phone' | 'Special Offers'
// export enum ProductType {
//   Internet = 'Internet',
//   Phone = 'Phone',
//   Tv = 'Tv',
//   SpecialOffers = 'Special Offers'
// }

export interface LOCMap {
  Company?: number
  Division?: number
  Franchise?: number
  'Franchise Name'?: string
  'Map To Location Type'?: LocationType
  'To Location'?: string
  Zip?: number
}

export interface MarketingText extends Item {
  'Subtitle[default]'?: string
  'Subtitle[included]'?: string
  'Subtitle[required]'?: string
  'Subtitle[upgrade]'?: string
  Title?: string
  Tooltip?: string
  'Tooltip Component'?: string
  'Tooltip Composite'?: string
  'Tooltip Image'?: string[]
  'Tooltip[included]'?: string
  'Tooltip[required]'?: string
  Type?: SubcategoryElement
}

export type SubcategoryElement = string

export interface CartTotal extends FeedItem {
  subItems: (Package | FeedItem)[]
}

export interface Package extends FeedItem {
  Price?: ItemPrice
  Image?: string
  backgroundImage?: string
  'Included Equipment'?: EquipmentRef[]
  'Included Upgrades'?: UpgradeRef[]
  'Required Equipment'?: EquipmentRef[]
  'Required Upgrades'?: UpgradeRef[]
  'Marketing Text 1'?: string
  'Marketing Text 2'?: string
  'Marketing Text 3'?: string
  'Marketing Text 4'?: string
  'Marketing Text 5'?: string
  'Marketing Text 6'?: string
  'Marketing Text 7'?: string
  'Marketing Text 8'?: string
  'Offer Duration'?: string
  'Under Price Text'?: string
  Products: Product[]
  Promos?: Promo[]
  Upgrades?: Upgrade[]
  View?: boolean
  lobs?: ProductType[]
  SkipCustomization?: number
  BroadbandLabel?: BroadbandLabel[]
  uniquePlanId?: string
}

// export const apackage: package = {
//   name: '',
//   tag: '',
//   itemtype: '',
//   'billing codes': [],
//   description: '',
//   disclaimer: '',
//   fees: [],
//   image: '',
//   'included equipment': [],
//   'included upgrades': [],
//   'location type': '',
//   locations: [],
//   'marketing text 1': '',
//   'marketing text 2': '',
//   'marketing text 3': '',
//   'marketing text 4': '',
//   'marketing text 5': '',
//   'marketing text 6': '',
//   'marketing text 7': '',
//   'marketing text 8': '',
//   'monthly price': 0,
//   products: [],
//   promos: [],
//   upgrades: [],
//   view: false,
//   lobs: []
// }

export interface Fee extends FeedItem {
  'Fee Group'?: string
  FeeType?: string
  Rate?: number
}

export interface FeeGroup extends Fee {
  Fees: Fee[]
}

export interface BillingCode extends Fee {
  Abbreviation?: Abbreviation
  'Billing Code'?: string
  'Billing Type'?: BillingType
  BillingGroup?: string
  Category?: Category
  ChargeType?: ChargeTypeKey
  PricePrefix?: string
  'Monthly Price'?: MonthlyPrice
  Source?: Source
  WorkOrderType?: WorkOrderType
  AttributeName?: AttributeName
  QtyType?: QtyType
}

export type Abbreviation = string
export type BillingType = string
export type Category = string
export type Source = string
export type BillingCodeTag = string
export type WorkOrderType = string
export type AttributeName = string
export type QtyType = 'single' | 'multiple'

export interface Group extends Item {
  'Product Type'?: ProductType
  'Min Tvs'?: number
  'Required Equipment'?: Equipment[]
  'Required Upgrades'?: Upgrade[]
  'Excluded Equipment'?: Equipment[]
  'Excluded Upgrades'?: Upgrade[]
}

export type FeeOrigBillingCodes = string
export type TagString = string

export interface Product extends FeedItem {
  'Component Order'?: number
  Equipment?: Equipment[]
  'Num Channels'?: number
  'Product Type'?: ProductType
  'Included Equipment'?: EquipmentRef[]
  'Required Equipment'?: EquipmentRef[]
  'Included Upgrades'?: UpgradeRef[]
  'Required Upgrades'?: UpgradeRef[]
  Speed?: StringOrNumber
  'Speed Unit'?: SpeedUnit
  'UI Component'?: UIComponent
  Upgrades?: Upgrade[]
}

export interface Equipment extends FeedItem {
  Default?: Default
  Dependencies?: ProductType[]
  Group?: string
  GroupType?: string
  Make?: string
  Max?: number
  Min?: number
  Parent?: string
  ProgressionParent?: string
  'Price Calculation'?: PriceCalculation
  'Product Type'?: ProductType
}

export interface CountedEquipmentGroup extends Equipment {
  subItems?: Equipment[]
}

export type Default = string
export type EquipmentRef = string
export type UpgradeRef = string
export type PriceCalculation = string
export type SpeedUnit = string
export type BroadbandLabelRef = string
export enum UIComponent {
  Internet = 'Internet',
  Phone = 'Phone',
  Tv = 'Tv'
}

// export enum UpgradeItemType {
//   Upgrade = 'Upgrade'
// }

export interface Promo extends FeedItem {
  'Mobile Description'?: MobileDescription
  Price?: ItemPrice
  'Promo Type'?: PromoType
  REGEX?: string
}

export type PromoName = string

export type MobileDescription = string
export type PromoOrigBillingCodes = string
export enum PromoType {
  Automatic = 'Automatic',
  UserInput = 'User Input'
}
export enum PromoItemType {
  Promo = 'Promo'
}

export interface Upgrade extends FeedItem {
  'Product Type'?: ProductType
  'Component Order'?: number
  Dependencies?: ProductType[]
  Max?: number
  Min?: number
  Price?: ItemPrice
  Subcategory?: SubcategoryElement
  Group?: string
  UpgradeText?: string
  Default?: string
}

export interface RefData {
  'Fee Groups'?: string[]
  'Item Types'?: string[]
  'Location Types'?: string[]
  'Product Types'?: ProductType[]
  'Promo Types'?: PromoType[]
  Subcategory?: SubcategoryElement[]
  TagKeys?: string[]
  Tags?: string[]
  'UI Component'?: UIComponent[]
}

export interface TagMap {
  existingTag?: ExistingTag
  matchExpression?: string
  matchType?: string
  matchTypes?: string
  newTag?: NewTag
  newTagValue?: NewTagValue
  regexModifiers?: string
}

export type FoundTagMap = TagMap & {
  newTagActual: string
  existingTagValues: string
  newTagsOriginalVal: string
  success: boolean
}

export type ExistingTag = string
export type NewTag = string
export type NewTagValue = string

export interface Tag {
  // Tag: string
  [key: string]: string | number
}

export interface CronDef {
  cron: string
}
export interface TagWindow extends Item {
  window: CronDef[]
  duration?: string | number
  description?: string
}
