<template>
  <div class="entire-container" id="offer-header">
    <v-card
      class="mx-auto rounded-mb-3 rounded-pt-4"
      ref="pkgCard"
      :min-width="minWidth"
      :width="packageMaxWidth(filterPackagesCount)"
      :max-width="packageMaxWidth(filterPackagesCount)"
      color="black"
      elevation="8"
      id="offer-header"
      v-on="isOfferTileClickable ? { click: () => selectPackage(packageData) } : {}"
    >
      <v-card flat color="transparent" class="items-container">
        <div v-if="packageData.Description" id="met-offer-header">
          <div style="color: black" id="offer-header-description" v-html="packageData.Description" />
          <TooltipDebug :name="packageData.Description" :item="packageData" />
        </div>
        <v-img v-else id="met-offer-header" justify="top" src="/api/content/OfferHeaderBlack.png"></v-img>
        <v-row class="text-center" align="start" justify="center">
          <v-col align="start">
            <v-row v-if="showDisplayName" align="center" justify="center">
              <span id="package-description" class="speed">
                {{ displayName(packageData) }}<TooltipDebug :name="packageData.Description" :item="packageData" />
              </span>
            </v-row>
            <v-row justify="center" id="package-display-row" class="text-center">
              <v-col
                cols="12"
                id="package-display-name"
                :style="{ '--color': displayNameTextColor }"
                class="text-center px-1 pb-0 pt-3"
                :class="{
                  'packageName-small': numberOfProducts(packageData) < 3,
                  packageName: numberOfProducts(packageData) >= 3
                }"
              >
                {{ itemDisplayName(packageData) }}
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <span id="package-price" class="price"
                >${{ priceParts(packageData)[0] }}.{{ priceParts(packageData)[1] }}{{ duration(packageData) }}</span
              >
            </v-row>
            <v-row align="center" justify="center">
              <span>
                <hr :width="hrWidth(filterPackagesCount)" noshade color="white" style="margin-top: 1rem" />
              </span>
            </v-row>
            <v-row
              v-if="showUnderPriceText && packageData['Under Price Text']"
              justify="center"
              style="min-height: 82px"
              class="text-center"
            >
              <v-col class="text-center py-5 under-price-text" :id="underPriceId(filterPackagesCount)">
                <div id="under-price-text-row" :style="{ '--height': underPriceTextHeight(filterPackagesCount) }">
                  <span id="package-under-price-text" v-html="packageData['Under Price Text']"></span>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="packageData['Marketing Text 1']" class="marketing-text">
              <span class="description"
                ><v-img
                  height="20px"
                  width="20px"
                  src="/api/content/Metronet-Checkmark.png"
                  :color="marketingTextIconColor"
                  class="checkmark-icon"
                ></v-img>
                <div class="pl-2" v-html="packageData['Marketing Text 1']"></div
              ></span>
            </v-row>
            <v-row v-if="packageData['Marketing Text 2']" class="marketing-text">
              <span class="description"
                ><v-img
                  height="20px"
                  width="20px"
                  src="/api/content/Metronet-Checkmark.png"
                  :color="marketingTextIconColor"
                  class="checkmark-icon"
                ></v-img>
                <div class="pl-2" v-html="packageData['Marketing Text 2']"></div
              ></span>
            </v-row>
            <v-row v-if="packageData['Marketing Text 3']" class="marketing-text">
              <span class="description"
                ><v-img
                  height="20px"
                  width="20px"
                  src="/api/content/Metronet-Checkmark.png"
                  :color="marketingTextIconColor"
                  class="checkmark-icon"
                ></v-img>
                <div class="pl-2" v-html="packageData['Marketing Text 3']"></div
              ></span>
            </v-row>
            <v-row v-if="packageData['Marketing Text 4']" class="marketing-text">
              <span class="description"
                ><v-img
                  height="20px"
                  width="20px"
                  src="/api/content/Metronet-Checkmark.png"
                  :color="marketingTextIconColor"
                  class="checkmark-icon"
                ></v-img>
                <div class="pl-2" v-html="packageData['Marketing Text 4']"></div
              ></span>
            </v-row>
            <v-row v-if="packageData['Marketing Text 5']" class="marketing-text">
              <span class="description"
                ><v-img
                  height="20px"
                  width="20px"
                  src="/api/content/Metronet-Checkmark.png"
                  :color="marketingTextIconColor"
                  class="checkmark-icon"
                ></v-img>
                <div class="pl-2" v-html="packageData['Marketing Text 5']"></div
              ></span>
            </v-row>
            <v-row v-if="packageData['Marketing Text 6']" class="marketing-text">
              <span class="description"
                ><v-img
                  height="20px"
                  width="20px"
                  src="/api/content/Metronet-Checkmark.png"
                  :color="marketingTextIconColor"
                  class="checkmark-icon"
                ></v-img>
                <div class="pl-2" v-html="packageData['Marketing Text 6']"></div
              ></span>
            </v-row>
            <v-row v-if="packageData['Marketing Text 7']" class="marketing-text">
              <span class="description"
                ><v-img
                  height="20px"
                  width="20px"
                  src="/api/content/Metronet-Checkmark.png"
                  :color="marketingTextIconColor"
                  class="checkmark-icon"
                ></v-img>
                <div class="pl-2" v-html="packageData['Marketing Text 7']"></div
              ></span>
            </v-row>
            <v-row v-if="packageData['Marketing Text 8']" class="marketing-text">
              <span class="description"
                ><v-img
                  height="20px"
                  width="20px"
                  src="/api/content/Metronet-Checkmark.png"
                  :color="marketingTextIconColor"
                  class="checkmark-icon"
                ></v-img>
                <div class="pl-2" v-html="packageData['Marketing Text 8']"></div
              ></span>
            </v-row>
            <v-row v-if="packageData['Marketing Text 9']" class="marketing-text">
              <span class="description"
                ><v-img
                  height="20px"
                  width="20px"
                  src="/api/content/Metronet-Checkmark.png"
                  :color="marketingTextIconColor"
                  class="checkmark-icon"
                ></v-img>
                <div class="pl-2" v-html="packageData['Marketing Text 9']"></div
              ></span>
            </v-row>
          </v-col>
        </v-row>
        <v-row align="end" justify="center">
          <v-btn
            id="package-met-button"
            width="175px"
            rounded
            class="button-margin"
            large
            dark
            elevation="12"
            v-on="!isOfferTileClickable ? { click: () => selectPackage(packageData) } : {}"
            color="yellow"
            >{{ offerButtonText }}
          </v-btn>
        </v-row>
      </v-card>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import useUiConfig from '@/components/shared/useUiConfig'
import usePackage from './usePackage'
import { itemDisplayName } from '@adg/catalog/src/modules/Catalog'
import { Package } from '@adg/catalog/src/modules/Catalog'
import { mdiCheckCircleOutline } from '@mdi/js'
import TooltipDebug from '@/components/shared/tooltip/TooltipDebug.vue'
import $store from '@/store'

interface PackageProps {
  pkgHeight: number
  minWidth: number
  colorIndex: number
  packageData: Package
  filterPackagesCount: number
  displayNameTextColor: string
}
export default defineComponent({
  name: 'PackageSquare',
  props: {
    pkgHeight: Number,
    minWidth: Number,
    colorIndex: Number,
    packageData: Object,
    filterPackagesCount: Number,
    displayNameTextColor: String
    //    packageData: {
    //      Name: String,
    //      MonthlyPrice: String,
    //      Products: [{ name: String, speed: String }]
    //    }
  },
  components: {
    TooltipDebug
  },
  setup(props: PackageProps, { emit, refs, root: { $vuetify } }) {
    const { offerHeaderColors, offerButtonColors } = useUiConfig($store)
    const {
      selectPackage,
      packageCardSquareCorners,
      offerButtonText,
      image,
      imageToUse,
      numberOfProducts,
      priceParts,
      speedUnits,
      displayName,
      underPriceId,
      packageMaxWidth,
      offerButtonColor,
      showSpeedUnits,
      marketingTextIconColor,
      showDescription,
      showDisplayName,
      showUnderPriceText,
      hrWidth,
      duration,
      underPriceTextHeight,
      isOfferTileClickable
    } = usePackage($store, $vuetify, emit)

    return {
      displayName,
      speedUnits,
      selectPackage,
      imageToUse,
      priceParts,
      numberOfProducts,
      image,
      itemDisplayName,
      underPriceId,
      packageMaxWidth,
      offerButtonColors,
      offerButtonText,
      mdiCheckCircleOutline,
      offerButtonColor,
      offerHeaderColors,
      duration,
      packageCardSquareCorners,
      showSpeedUnits,
      marketingTextIconColor,
      showDescription,
      showDisplayName,
      showUnderPriceText,
      hrWidth,
      underPriceTextHeight,
      isOfferTileClickable
    }
  }
})
</script>

<style scoped>
.price {
  font-size: 24px;
  font-weight: 400;
  color: white;
}

#package-display-name {
  color: var(--color);
}

#under-price-text-row {
  height: var(--height);
}
.dollar {
  font-size: 38px;
  top: -0.9em;
  font-weight: bold;
  margin-left: -4px;
}
.packageName-small {
  height: 40px !important;
  min-height: 40px !important;
  padding-top: 0px !important;
  font-size: 28px !important;
  font-weight: 600 !important;
}
.cents {
  font-size: 24px;
  font-weight: bold;
  vertical-align: top;
  line-height: 0px;
}
.checkmark-icon {
  padding-right: 0.4rem;
  display: flex;
  flex-direction: row;
}
.button-margin {
  margin-bottom: 2rem !important;
}
.duration {
  font-size: 12px;
  vertical-align: top;
  line-height: 20px;
  margin-left: -30px;
}
.package-title {
  font-size: 28px;
}
.v-application .packageHeder {
  background-color: purple;
  color: red;
}

.v-sheet.v-card {
  border-radius: 15px;
}
.mbps {
  font-size: 18px;
  color: black;
}
.speed {
  font-size: 16px;
  font-weight: bold;
  color: white;
}
.underline {
  width: 100px;
  border-bottom: thin solid black;
  height: 20px;
}
.description {
  font-size: 16px;
  color: white;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.button-special {
  top: 25px;
  max-width: 105px;
}
.marketing-text {
  flex-wrap: wrap;
  justify-content: left;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 40px;
  margin-right: 40px;
}
.entire-container {
  display: flex;
  height: 100%;
}
.items-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#offer-header {
  padding: 0 !important;
  max-width: 100% !important;
}
.v-icon__svg {
  margin-bottom: 20px !important;
}
</style>
