<template>
  <v-card
    :tile="packageCardSquareCorners"
    :class="packageCardClass"
    ref="pkgCard"
    :min-width="minWidth"
    :max-width="packageMaxWidth()"
    :min-height="mktTextLines(packageData) > 6 ? 660 : 560"
    :id="offerCardId(packageData, colorIndex)"
    height="100%"
    v-on="isOfferTileClickable ? { click: () => selectPackage(packageData) } : {}"
  >
    <div :style="{ background: backgroundColor(colorIndex, offerHeaderColors, offerHeaderSecondaryColors) }">
      <v-app-bar dark color="transparent" height="150" :src="backgroundImage(packageData)">
        <v-card flat height="125" width="100%" color="transparent" justify="center">
          <v-img contain height="110" width="100%" :src="image(packageData)">
            <v-card flat color="transparent">
              <v-row class="text-center" align="center" justify="center" style="height: 100px">
                <v-col class="package-title"
                  >{{ packageDescription(packageData) }}<TooltipDebug :name="packageData.Description" :item="packageData"
                /></v-col>
              </v-row>
            </v-card>
          </v-img>
        </v-card>
      </v-app-bar>
    </div>

    <!-- -------------------------------------------- -->
    <!--    PRICE -->
    <!-- -------------------------------------------- -->
    <v-container>
      <!-- <div v-if="abovePriceText">
        <v-row>
          <v-col>
            <span>{{ abovePriceText }} </span>
          </v-col>
        </v-row>
      </div> -->
      <v-row justify="center">
        <v-card tile flat style="margin-top: -35px" width="90%" :height="abovePriceText ? '120' : '100'">
          <v-container class="pa-0">
            <v-row no-gutters>
              <v-col cols="12" class="pt-1">
                <div v-if="abovePriceText" class="text-center">
                  <span class="headline" v-html="abovePriceText"></span>
                </div>
                <v-card-text
                  :class="abovePriceText ? 'px-0 pt-8 text-center price-container' : 'px-0 pt-12 text-center price-container'"
                >
                  <sup id="package-dollar-sign" class="dollar">$</sup>
                  <span id="package-price" class="price">{{ priceParts(packageData)[0] }}</span>
                  <sup id="package-cents" class="cents" v-if="!(priceParts(packageData)[1] === '00' && hidePackagePriceZeroes)">{{
                    priceParts(packageData)[1]
                  }}</sup>
                  <div class="duration-container">
                    <span id="pacakge-duration" v-html="duration(packageData)" class="duration"></span>
                  </div>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-row>
    </v-container>

    <v-card flat v-if="packageData['Under Price Text']">
      <v-container class="under-price-text-padding pb-0">
        <v-row justify="center" style="min-height: 48px" class="text-center">
          <v-col class="text-center pb-0 under-price-text" :id="underPriceId(filterPackagesCount)">
            <span id="package-under-price-text" v-html="packageData['Under Price Text']"></span>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card flat :height="packageNameHeight(packageData)">
      <v-container class="py-0">
        <v-row justify="center" class="text-center">
          <v-col
            id="package-display-name"
            cols="12"
            class="black--text text-center headline px-1 pb-0 pt-3"
            :class="{
              'packageName-small': numberOfProducts(packageData) < 3,
              packageName: numberOfProducts(packageData) >= 3
            }"
          >
            {{ packageDisplayName(packageData) }}
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card flat>
      <v-container class="pt-0">
        <v-row justify="center">
          <v-btn
            elevation="6"
            large
            width="125"
            dark
            :rounded="showRoundedOfferButton"
            :color="offerButtonColors[colorIndex % offerButtonColors.length]"
            v-html="offerButtonText"
            v-on="!isOfferTileClickable ? { click: () => selectPackage(packageData) } : {}"
          >
          </v-btn>
        </v-row>
      </v-container>
    </v-card>
    <!--    <v-card flat height="180">-->
    <v-container fluid class="pa-0">
      <v-row justify="center">
        <div v-if="noBullets">
          <v-row v-for="text in marketingTextArray(packageData)" :key="text" class="marketing-text">
            <span class="description" v-html="text"></span>
          </v-row>
        </div>
        <div v-else>
          <ul class="mx-10 pt-2">
            <li class="body-2 pb-1" v-if="packageData['Marketing Text 1']">
              <span class="font16" v-html="packageData['Marketing Text 1']"></span>
            </li>
            <li class="body-2 pb-1" v-if="packageData['Marketing Text 2']">
              <span class="font16" v-html="packageData['Marketing Text 2']"></span>
            </li>
            <li class="body-2 pb-1" v-if="packageData['Marketing Text 3']">
              <span class="font16" v-html="packageData['Marketing Text 3']"></span>
            </li>
            <li class="body-2 pb-1" v-if="packageData['Marketing Text 4']">
              <span class="font16" v-html="packageData['Marketing Text 4']"></span>
            </li>
            <li class="body-2 pb-1" v-if="packageData['Marketing Text 5']">
              <span class="font16" v-html="packageData['Marketing Text 5']"></span>
            </li>
            <li class="body-2 pb-1" v-if="packageData['Marketing Text 6']">
              <span class="font16" v-html="packageData['Marketing Text 6']"></span>
            </li>
            <li class="body-2 pb-1" v-if="packageData['Marketing Text 7']">
              <span class="font16" v-html="packageData['Marketing Text 7']"></span>
            </li>
            <li class="body-2 pb-1" v-if="packageData['Marketing Text 8']">
              <span class="font16" v-html="packageData['Marketing Text 8']"></span>
            </li>
            <li class="body-2 pb-1" v-if="packageData['Marketing Text 9']">
              <span class="font16" v-html="packageData['Marketing Text 9']"></span>
            </li>
          </ul>
        </div>
      </v-row>
    </v-container>
    <!--    </v-card>-->
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import useUiConfig from '@/components/shared/useUiConfig'
import usePackage from './usePackage'
import { itemDisplayName } from '@adg/catalog/src/modules/Catalog'
import { Package } from '@adg/catalog/src/modules/Catalog'
import TooltipDebug from '@/components/shared/tooltip/TooltipDebug.vue'
import $store from '@/store'

interface PackageProps {
  pkgHeight: number
  minWidth: number
  colorIndex: number
  packageData: Package
  filterPackagesCount: number
}
export default defineComponent({
  name: 'Package',
  props: {
    pkgHeight: Number,
    minWidth: Number,
    colorIndex: Number,
    packageData: Object,
    filterPackagesCount: Number
  },
  components: {
    TooltipDebug
  },
  setup(props: PackageProps, { emit, refs, root: { $vuetify } }) {
    const {
      underPriceId,
      selectPackage,
      packageCardClass,
      packageCardSquareCorners,
      offerButtonText,
      offerCardId,
      packageNameHeight,
      numberOfProducts,
      priceParts,
      packageMaxWidth,
      hidePackagePriceZeroes,
      mktTextLines,
      backgroundColor,
      duration,
      abovePriceText,
      marketingTextArray,
      backgroundImage,
      noBullets,
      image,
      packageDescription,
      packageDisplayName,
      showRoundedOfferButton,
      isOfferTileClickable
    } = usePackage($store, $vuetify, emit)

    const { offerHeaderColors, offerButtonColors, offerHeaderSecondaryColors } = useUiConfig($store)

    return {
      selectPackage,
      priceParts,
      image,
      backgroundImage,
      numberOfProducts,
      mktTextLines,
      packageNameHeight,
      backgroundColor,
      marketingTextArray,
      duration,
      itemDisplayName,
      packageMaxWidth,
      underPriceId,
      offerCardId,
      packageDescription,
      packageDisplayName,
      offerButtonColors,
      offerHeaderSecondaryColors,
      offerHeaderColors,
      packageCardClass,
      packageCardSquareCorners,
      hidePackagePriceZeroes,
      abovePriceText,
      offerButtonText,
      noBullets,
      showRoundedOfferButton,
      isOfferTileClickable
    }
  }
})
</script>

<style scoped>
.packageName {
  min-height: 115px;
}
.packageName-small {
  min-height: 80px;
}
.price-container {
  display: inline-flex;
  justify-content: center;
}
.price {
  font-size: 96px;
  /*font-weight: 300;*/
  font-weight: bold;
}
.dollar {
  font-size: 38px;
  top: -0.1em;
  font-weight: bold;
  margin-left: -4px;
}
.cents {
  font-size: 24px;
  font-weight: bold;
  vertical-align: top;
  line-height: 0px;
}
.duration {
  font-size: 12px;
  vertical-align: top;
  /* line-height: 20px; */
  /* margin-left: -30px; */
}
.duration-container {
  text-align: start;
  max-width: 33%;
  margin-left: -27px;
  line-height: 15px;
}
.v-application .packageHeder {
  background-color: purple;
  color: red;
}
.package-title {
  font-size: 28px;
}
.v-sheet.v-card {
  border-radius: 15px;
}
.marketing-text {
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 40px;
  margin-right: 40px;
  justify-content: center;
}
.description {
  font-size: 16px;
  text-align: center;
}
.font16 {
  font-size: 16px;
}
.above-price-text {
  /* position: absolute; */
  margin-left: 20px;
  font-size: 22px;
}

.under-price-text {
  padding-top: 0 !important;
  font-weight: bold !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
}
</style>
