import { ContactInfo } from './../../../common/Payments'
import { PaymentInfo } from '../../Converge/converge'
import { MatchType } from '../../../common/MatchType'
import { LegacyAutomationState } from '../../../common/AutomationState'
import { BaseAddress, FullAddress } from '../../Address/address'
import { Catalog, ConfigItems, newCatalog, OrderSchedule, SalesLeadInfo, ShoppingOrder, TagMap, Tags, Upgrade } from '../../Catalog'
import { Cart, newCart } from '../../Order'
import { Note } from '../../Ticket/Note'
import { Place } from '../../../common/addressUtil'
import { PaymentDisposition } from '../../../common/Payments'
import { AddressMatchResults } from '../../Client/client'

export type QualityScoreStatus = 'success' | 'failure' | 'disabled' | 'received' | 'unknown'

export type PossiblePlace = Place | undefined | null

export const shopperVersion = 2

export type ShopperStatus = 'new' | 'unknown' | 'submittedPreSale' | 'submittedOrder'

export enum SalesType {
  STANDARD = 'standard',
  PRESALE = 'presale',
  UPGRADE = 'upgrade',
  LEADCAPTURE = 'leadcapture'
}

export interface IShopper {
  id: string
  buyflowStartId?: string
  appLoadId?: string
  version: number
  timezone?: string
  status: ShopperStatus
  orgId: string
  clientId: string
  brand: string
  catalog?: Catalog
  googleClientId?: string
  googleSessionId?: string
  googleSessionNumber?: string
  firstName?: string
  lastName?: string
  phone?: string
  email?: string
  place?: Place
  formattedAddress?: string
  punctuatedAddress?: string
  matchType?: MatchType
  matchedAddress?: FullAddress
  externalAccountNumber?: string | null
  existingServiceOption?: string
  externalWorkOrder?: string | null
  state?: SavedState
  order?: ShoppingOrder
  orderNumber?: number
  cart: Cart
  customInfo: CustomInfo
  ssResponse?: any
  automationState?: LegacyAutomationState
  tags?: Tags
  tagMap?: TagMap[]
  idImage?: string
  logMsg?: string
  cancelAutomation?: boolean
  hubSpotContactId?: string
  zendeskContactId?: string
  log?: string[]
  agentNotes?: Note[]
  fullyAutomated?: boolean | null
  salesType: SalesType
  uniquePlanId?: string
  leadFormName?: string
  qualityScore?: number
  qualityScoreTime?: number
  qualityScoreStatus?: QualityScoreStatus
  matchResults?: AddressMatchResults
  riskScore?: string
  riskScoreSource?: string
  riskScoreDetails?: string
  /** Readonly. Extracted field. */
  salesAgentId?: string | null
  /** Readonly. Extracted field. */
  salesAgentName?: string | null
  /** Readonly. Extracted field. */
  saAddressLine1?: string | null
  /** Readonly. Extracted field. */
  saAddressLine2?: string | null
  /** Readonly. Extracted field. */
  saCity?: string | null
  /** Readonly. Extracted field. */
  saState?: string | null
  /** Readonly. Extracted field. */
  saZip?: string | null
  /** Readonly. Extracted field. */
  monthlyTotal?: number | null
  /** Readonly. Extracted field. */
  packageName?: string | null
  /** Readonly. Extracted field. */
  referralId?: string | null
}

export interface TermsConsent {
  termsAndConditionsConsent: boolean
  termsAndConditionsConsentTimestamp: string
}

export interface PhoneConsent {
  consentToAgencyAgreement: boolean
  consentToAgencyAgreementTimestamp: string
}

export interface SecurityQuestion {
  question: string
  answer: string
}

export interface Referral {
  method?: string
  comment?: string
  referredBy?: string
}

export interface CustomInfo {
  pin?: string
  isStudent?: boolean
  ssn?: string
  lease?: {
    start: string
    end: string
  }
  driversLicenseNum?: string
  studentId?: string
  idImage?: string
  previousAddress?: string
  previousAddressParsed?: FullAddress
  billingAddress?: string
  billingAddressParsed?: FullAddress
  acceptTextAlerts?: boolean
  internationalPhone?: boolean
  paymentInfo?: PaymentInfo
  paymentDisposition: PaymentDisposition
  canSelfInstall?: boolean
  preSale?: boolean
  initialPreSale?: boolean
  returningPreSale?: boolean
  dob?: string
  dobString?: string
  creditChecks: any[]
  creditCheckPassed: boolean
  businessName?: string
  currentPhone?: {
    number?: string
    billingName?: string
    carrier?: string
    accountNumber?: string
  }
  readyDate?: string
  termsConsent?: TermsConsent
  phonePortConsent?: PhoneConsent
  textArea?: string
  securityQuestions?: SecurityQuestion[]
  referrals?: Referral[]
  referredBy?: string
  variablePromotionCode?: string
  accountFields: { [key: string]: string | boolean }
  preferredContactMethod?: string
  reoccuringPayments?: boolean
  billingContactInfo?: ContactInfo
  custCompanyName?: string
  comments?: string
  modem?: {
    ssid?: string
    username?: string
    password?: string
  }
  acpApplicationID?: string
  acpFirstName?: string
  acpLastName?: string
  acpDOBString?: string
  acpBQPFirstName?: string
  acpBQPLastName?: string
  acpBQPDOBString?: string
  newPhoneNumber?: string
}

export type SavedState = {
  currentStep: number
  serviceability: ServiceabilityState
  availablePackageUpgrades: Upgrade[]
  schedule: OrderSchedule | null
  validation: Validation
  configItems: ConfigItems
}

export interface Validation {
  accountInfo: boolean
  phoneInfo: boolean
}

export interface ServiceabilityState {
  addressSuggestions: Place[]
  previousAddressSuggestions: Place[]
  billingAddressSuggestions: Place[]
  addressVerified?: boolean
  matchedAddress?: FullAddress
  salesLead?: SalesLeadInfo
}

export const newCustomInfo = (): CustomInfo => ({
  pin: '',
  isStudent: false,
  ssn: '',
  lease: {
    start: '',
    end: ''
  },
  driversLicenseNum: '',
  studentId: '',
  idImage: '',
  previousAddress: '',
  billingAddress: '',
  billingAddressParsed: {} as FullAddress,
  acceptTextAlerts: false,
  internationalPhone: false,
  paymentInfo: {} as PaymentInfo,
  paymentDisposition: {
    done: false,
    transactionId: null,
    status: 'initial',
    authorization: null,
    errors: [],
    processorDisposition: {
      type: 'Generic',
      data: null
    },
    billerDisposition: null,
    paymentContact: null,
    paymentMethod: null
  },
  canSelfInstall: false,
  preSale: false,
  initialPreSale: false,
  returningPreSale: false,
  dob: '',
  dobString: '',
  creditChecks: [],
  creditCheckPassed: false,
  businessName: '',
  currentPhone: {
    number: '',
    billingName: '',
    carrier: '',
    accountNumber: ''
  },
  readyDate: '',
  termsConsent: undefined,
  phonePortConsent: undefined,
  textArea: '',
  securityQuestions: [],
  referrals: [],
  accountFields: {},
  preferredContactMethod: '',
  referredBy: '',
  billingContactInfo: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: {
      addressLine: '',
      city: '',
      state: '',
      zip: ''
    }
  } as ContactInfo
})

export const newShopper = (): IShopper => {
  return {
    id: '',
    version: shopperVersion,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    appLoadId: undefined,
    buyflowStartId: undefined,
    status: 'new',
    clientId: '',
    brand: '',
    orgId: '',
    catalog: newCatalog(),
    googleClientId: '',
    googleSessionId: '',
    googleSessionNumber: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    place: {
      street_line: '',
      secondary: '',
      city: '',
      state: '',
      zipcode: '',
      entries: 0
    },
    formattedAddress: '',
    cart: newCart(),
    matchType: MatchType.NONE,
    matchedAddress: { salesType: undefined },
    externalAccountNumber: '',
    externalWorkOrder: '',
    automationState: undefined,
    idImage: '',
    customInfo: newCustomInfo(),
    cancelAutomation: false,
    qualityScore: 1.0,
    qualityScoreStatus: 'unknown',
    log: [],
    agentNotes: [],
    fullyAutomated: false,
    zendeskContactId: '',
    tags: { preFetch: {} },
    salesType: SalesType.STANDARD
  }
}
