
<v-card class="broadband-card" :min-width="minWidth ? minWidth : '225px'" :max-width="maxWidth ? maxWidth : '400px'">
  <v-container>
    <div v-if="closeButtonInLabel" class="header">
      <div class="close-col">
        <v-icon @click="$emit('labelClosed')" class="icon">mdi-arrow-u-left-top-bold</v-icon>
      </div>
    </div>
    <div v-html="labelHtml"></div>
    <v-row v-if="selectButtonInLabel">
      <v-col class="button-col">
        <v-btn
          :class="`button ${getCatalogItemClassName(packageData?.Name, 'Package')}`"
          rounded
          v-html="offerButtonText"
          @click="$emit('labelSelectButtonClicked')"
          dark
          :color="getOfferButtonColor({ type: '' }, colorIndex)"
        >
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
  <div></div>
</v-card>
