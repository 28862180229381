<template v-if="broadbandConfigString">
  <v-card class="broadband-card" :min-width="minWidth ? minWidth : '225px'" :max-width="maxWidth ? maxWidth : '400px'">
    <v-container>
      <div v-if="closeButtonInLabel" class="header">
        <div class="close-col">
          <v-icon @click="$emit('labelClosed')" class="icon">mdi-arrow-u-left-top-bold</v-icon>
        </div>
      </div>
      <div v-html="labelHtml"></div>
      <v-row v-if="selectButtonInLabel">
        <v-col class="button-col">
          <v-btn
            :class="`button ${getCatalogItemClassName(packageData?.Name, 'Package')}`"
            rounded
            v-html="offerButtonText"
            @click="$emit('labelSelectButtonClicked')"
            dark
            :color="getOfferButtonColor({ type: '' }, colorIndex)"
          >
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <div></div>
  </v-card>
</template>

<script lang="ts">
import { computed, defineComponent, emit, ref } from '@vue/composition-api'
import $store from '@/store'
import { ConfigKeys, Package } from '@adg/catalog/src/modules/Catalog'
import useUiConfig from '@/components/shared/useUiConfig'
import { getCatalogItemClassName } from '@adg/catalog/src/common/utils'
import usePackage from './usePackage'
import { getConfigItem } from '@/components/shared/getConfigItem'
import { IShopper } from '@adg/catalog/src/modules/Shopper'
interface PackageProps {
  pkgHeight: number
  minWidth: number
  colorIndex: number
  packageData: Package
  filterPackagesCount: number
  hasUnderPriceText: boolean
  displayNameTextColor: string
}
export default defineComponent({
  name: 'BroadbandLabelBase',
  props: {
    packageData: {
      type: Object as () => Package,
      required: true
    },
    shopper: {
      type: Object as () => IShopper,
      required: true
    },
    broadbandConfigString: {
      type: String,
      required: true
    },
    approvedBroadbandLabelClasses: {
      type: Array as () => string[],
      required: false
    },
    selectButtonInLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    closeButtonInLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    colorIndex: {
      type: Number,
      required: false
    },
    labelHtml: {
      type: String,
      required: true
    },
    minWidth: {
      type: Number || String,
      required: false
    },
    maxWidth: {
      type: Number || String,
      required: false
    }
  },
  components: {},
  setup(props: PackageProps, { emit, refs, root: { $vuetify } }) {
    const { selectPackage, offerButtonText, getOfferButtonColor } = usePackage($store, $vuetify, emit)

    return {
      getCatalogItemClassName,
      selectPackage,
      offerButtonText,
      getOfferButtonColor
    }
  }
})
</script>

<style>
.broadband-card {
  max-width: 400px;
}
.broadband-card .col {
  display: flex;
  justify-content: center;
}
.broadband-card a {
  word-break: break-all;
}
.broadband-card .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: -15px;
}
.broadband-card .close-col {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  padding: 0px !important;
}
.broadband-card .close-col .v-icon {
  font-size: 30px;
  color: #ff000c;
  caret-color: #ff000c;
}
</style>
