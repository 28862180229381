<template>
  <v-container id="confirmation-background">
    <v-row v-show="printImageTop" id="printConfirmationImage" justify="center"></v-row>
    <v-row
      v-if="showConfirmationHeaderText"
      no-gutters
      id="confirmation-header-text"
      class="text-center grey--text no-print"
      :hide-print="hidePrintConfirmationTitle"
    >
      <v-col class="display-2" v-html="uiMacroParser(shopper, confirmationText)"></v-col>
    </v-row>
    <v-row
      no-gutters
      id="confirmation-subtitle-text"
      class="text-center grey--text no-print"
      :hide-print="hidePrintConfirmationTitle"
    >
      <v-col id="confirmation-subtitle" class="headline" v-html="uiMacroParser(shopper, confirmationSubtitle)"></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="7">
        <v-card :tile="sharpCorners" class="confirmation-card my-auto mx-auto">
          <v-toolbar flat id="confirmation-header" height="40" dark color="secondary">
            <v-toolbar-title :class="$vuetify.breakpoint.xsOnly ? 'text-center' : ''" id="confirmation-card-header-text"
              ><v-icon v-if="showConfirmationIcon" style="padding-right: 6px" :color="infoIconColor">
                {{ mdiInformationOutline }}
              </v-icon>
              <span
                v-html="
                  concatenateConfirmationCardHeaderText
                    ? uiMacroParser(shopper, confirmationHeaderText + confirmationCardHeaderText)
                    : uiMacroParser(shopper, confirmationCardHeaderText)
                "
              ></span>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-subtitle class="pb-0 pt-1">
            <v-container class="pa-0">
              <v-row>
                <v-col cols="12">
                  <span id="payment-row-style" v-html="message + additionalConfirmationMessages" />
                </v-col>
              </v-row>
              <v-row v-if="showSelfInstallInfo">
                <v-col cols="12">
                  <CustomSchedulingText />
                </v-col>
              </v-row>
            </v-container>
          </v-card-subtitle>
          <!-- dont worry about this, it preloads the image so it displays on print-->
          <v-img src="/api/content/printLogo.png?url" style="opacity: 0; position: absolute"></v-img>
          <v-row v-show="!printImageTop" id="printConfirmationImage" justify="center"></v-row>
          <a v-bind="imageAttrs" class="confirmation-image-container">
            <v-img :src="image" class="hidden-print-only confirmation-image"> </v-img
          ></a>
        </v-card>
      </v-col>
      <v-col cols="12" sm="5">
        <ConfirmationCart :sharpCorners="sharpCorners" @print="print" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, computed, ComputedRef } from '@vue/composition-api'
import ConfirmationCart from '@/components/order/cart/ConfirmationCart.vue'
import { getConfigBoolean, getConfigItem } from '../shared/getConfigItem'
import CustomSchedulingText from '@/components/order/scheduling/CustomSchedulingText.vue'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import { IShopper } from '@adg/catalog/src/modules/Shopper'
import { mdiInformationOutline } from '@mdi/js'
import $store from '@/store'
import StreamingServices from './StreamingServices.vue'
import { VContainer, VRow, VCol, VCard, VToolbar, VToolbarTitle, VIcon, VCardSubtitle, VImg } from 'vuetify/lib'
import { checkConditional } from '@adg/catalog/src/common/utils'
import { AdditionalConfirmationMessage, ConfirmationTextConfig } from '@adg/catalog/src/common/UIConfig'
import { uiMacroParser } from '@/utils/ShopperHelpers'

export default defineComponent({
  name: 'Confirmation',
  components: {
    ConfirmationCart,
    CustomSchedulingText,
    StreamingServices
  },
  setup() {
    const shopper = computed(() => $store.getters.getShopper as IShopper)
    const shopperFirstName = computed(() => shopper.value.firstName)
    // todo: how to type the below c
    const uiConfig = computed(() => $store.getters.getUIConfig.buyFlow.find((c) => c.name === 'confirmation'))
    const infoIconColor = computed(() => getConfigItem(ConfigKeys.infoIconColor) ?? '#08131f')

    const automationSuccess = computed(() => shopper.value.automationState?.status === 'success')
    const isAutomatedOrder = computed(() => automationSuccess.value && $store.getters.getAvailableSchedule?.automated === 'yes')
    const paymentFailed = computed(() => {
      const paymentInfoStatus = $store.getters.getPaymentInfo?.status
      return ['canceled', 'error', 'declined'].includes(paymentInfoStatus)
    })

    const confirmationGreeting = computed(() => getConfigItem(ConfigKeys.confirmationGreeting) ?? 'Thank you')

    const confirmationHeaderText = computed(() =>
      getConfigBoolean(ConfigKeys.showShopperNameInConfirmation) && shopperFirstName.value
        ? `${confirmationGreeting.value}, ${shopperFirstName.value}!`
        : `${confirmationGreeting.value}!`
    )

    const confirmationTextConfig = computed(
      () => (getConfigItem(ConfigKeys.confirmationTextConfig) as ConfirmationTextConfig[]) ?? undefined
    )

    const confirmationText = computed(() => {
      let message = ''
      if (!confirmationTextConfig.value) {
        message = confirmationHeaderText.value
      } else {
        confirmationTextConfig.value.forEach((textItem) => {
          if (textItem.type === 'text') {
            message += textItem.message
          } else if (textItem.type === 'shopperName') {
            message += shopperFirstName.value
          }
        })
      }
      return message
    })

    const showSelfInstallInfo = computed(
      () =>
        shopper.value.customInfo.canSelfInstall &&
        !shopper.value.customInfo.initialPreSale &&
        automationSuccess.value &&
        !paymentFailed.value
    )

    const additionalConfirmationMessages = computed((): string => {
      let additionalMessages = ''
      const additionalConfirmationMessages = getConfigItem(ConfigKeys.additionalConfirmationMessages) as
        | AdditionalConfirmationMessage[]
        | undefined
      if (additionalConfirmationMessages === undefined) return ''
      if (additionalConfirmationMessages.length && additionalConfirmationMessages.length > 0) {
        additionalConfirmationMessages.forEach((confirmationMessage) => {
          const conditionsMet = confirmationMessage.conditions.some((confirmationMessageCondition) => {
            //if multiple conditions grouped together, check each one. otherwise check the single condition.
            //if a single condition or group of conditions is true, append the message
            if (Array.isArray(confirmationMessageCondition)) {
              return checkConditional(confirmationMessageCondition, shopper.value)
            } else {
              //check conditional takes array of conditions, if single condition, put it in an array.
              return checkConditional([confirmationMessageCondition], shopper.value)
            }
          })
          if (conditionsMet) {
            additionalMessages += uiMacroParser(shopper.value, confirmationMessage.message)
          }
        })
      }
      return additionalMessages
    })

    const messageUnparsed = computed(() => {
      if (shopper.value.customInfo.initialPreSale && $store.getters.getConfigItems.preSaleConfirmationText) {
        return $store.getters.getConfigItems.preSaleConfirmationText
      }
      if (getConfigItem(ConfigKeys.confirmationText)) return getConfigItem(ConfigKeys.confirmationText)
      if (!uiConfig.value) return ''
      const message1 = uiConfig.value.message1?.join('') ?? ''
      const message2 = uiConfig.value.message2?.join('') ?? ''

      if (showSelfInstallInfo.value) return message1

      const showSecondaryMessage = paymentFailed.value || !isAutomatedOrder.value
      if (showSecondaryMessage && uiConfig.value.message2) {
        return message2
      } else return message1
    })

    const message = computed(() => uiMacroParser(shopper.value, messageUnparsed.value))

    const image = computed(() => getConfigItem(ConfigKeys.confirmationImage) ?? '')

    const confirmationSubtitle = computed(
      () => getConfigItem(ConfigKeys.confirmationSubtitle) ?? 'Your order was placed successfully'
    )

    const hidePrintConfirmationTitle = computed(() => (getConfigItem(ConfigKeys.hidePrintConfirmationTitle) ? 'true' : 'false'))
    const sharpCorners = computed(() => getConfigItem(ConfigKeys.cardSharpCorners) ?? false)

    const showConfirmationIcon = computed(() => getConfigItem(ConfigKeys.showConfirmationIcon))

    const showConfirmationHeaderText = computed(() => getConfigItem(ConfigKeys.showConfirmationHeaderText) ?? true)

    const confirmationCardHeaderText = computed(() => getConfigItem(ConfigKeys.confirmationCardHeaderText) ?? `WHAT'S NEXT`)

    const concatenateConfirmationCardHeaderText = computed(
      () => getConfigItem(ConfigKeys.concatenateConfirmationCardHeaderText) ?? false
    )

    const print = () => window.print()

    const printImageTop = computed(() => getConfigItem(ConfigKeys.printImageTop))

    const imageAttrs = computed(() => {
      const href = getConfigItem(ConfigKeys.confirmationImageHref)
      if (!href) return {}
      return {
        href,
        target: '_blank',
        rel: 'noopener noreferrer'
      }
    })

    return {
      print,
      uiConfig,
      message,
      image,
      showSelfInstallInfo,
      confirmationSubtitle,
      confirmationHeaderText,
      hidePrintConfirmationTitle,
      printImageTop,
      mdiInformationOutline,
      showConfirmationIcon,
      confirmationCardHeaderText,
      sharpCorners,
      imageAttrs,
      infoIconColor,
      additionalConfirmationMessages,
      showConfirmationHeaderText,
      concatenateConfirmationCardHeaderText,
      confirmationGreeting,
      confirmationTextConfig,
      confirmationText,
      shopper,
      uiMacroParser
    }
  }
})
</script>

<style>
@media print {
  #printConfirmationImage:before {
    content: url('/api/content/printLogo.png?url');
    transform: scale(0.7);
  }
  .no-print[hide-print='true'],
  .no-print[hide-print='true'] * {
    display: none !important;
  }
}

.confirmation-card {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
</style>
