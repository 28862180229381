
<div class="entire-container" id="offer-header">
  <v-card
    :tile="packageCardSquareCorners"
    class="mx-auto rounded-mb-3 rounded-pt-4"
    ref="pkgCard"
    min-width="225px"
    max-width="300px"
    color="white"
    elevation="8"
    id="offer-header"
    v-on="isOfferTileClickable ? { click: () => selectPackage(packageData) } : {}"
  >
    <v-card tile flat color="transparent" class="items-container">
      <v-img min-height="8px" max-height="8px" justify="top" :src="imageToUse(packageData)"> </v-img>
      <v-row class="text-center" align="start" justify="center">
        <v-col align="start">
          <v-row align="center" justify="center">
            <span id="package-display-name" class="speed">
              {{ displayName(packageData) }}<TooltipDebug :name="packageData.Description" :item="packageData" />
            </span>
          </v-row>
          <v-row v-if="showSpeedUnits" align="center" justify="center">
            <sup id="package-speed-units" class="mbps" align="center" justify="center">{{ speedUnits(packageData) }}</sup>
          </v-row>
          <v-row
            id="package-text-above-price-row"
            v-if="showTextAbovePrice && packageData['Above Price Text']"
            class="ma-0"
            align="center"
            justify="center"
          >
            <span id="package-text-above-price" align="center" justify="center" v-html="packageData['Above Price Text']"></span>
          </v-row>
          <v-row id="package-display-name-row" class="ma-0" align="center" justify="center">
            <span id="package-price" class="price">${{ priceParts(packageData)[0] }}</span>
            <span id="package-cents" class="price" v-if="!(priceParts(packageData)[1] === '00' && hidePackagePriceZeroes)">{{
              '.' + priceParts(packageData)[1]
            }}</span>
            <span id="package-duration" class="price">/mo</span>
          </v-row>
          <v-row v-if="showUnderPriceText" justify="center" style="min-height: 48px" class="text-center">
            <v-col class="text-center pb-0 under-price-text" :id="underPriceId(filterPackagesCount)">
              <span id="package-under-price-text" v-html="packageData['Under Price Text']"></span>
            </v-col>
          </v-row>
          <v-row justify="center" id="package-display-row" class="text-center">
            <v-col
              cols="12"
              id="package-display-name"
              class="black--text text-center headline px-1 pb-0 pt-3"
              :class="{
                'packageName-small': numberOfProducts(packageData) < 3,
                packageName: numberOfProducts(packageData) >= 3
              }"
            >
              {{ itemDisplayName(packageData) }}
            </v-col>
          </v-row>
          <v-row
            v-if="showUpperOfferButton"
            :id="numberOfProducts(packageData) === 1 ? 'upper-offer-button-single' : 'upper-offer-button'"
            align="end"
            justify="center"
          >
            <v-btn
              large
              dark
              :rounded="showRoundedOfferButton"
              elevation="5"
              v-on="!isOfferTileClickable ? { click: () => selectPackage(packageData) } : {}"
              :color="offerButtonColor"
              >{{ offerButtonText }}
            </v-btn>
          </v-row>
          <v-row id="marketing-text-1" v-if="packageData['Marketing Text 1']" class="marketing-text">
            <span class="description"
              ><v-icon :color="marketingTextIconColor" class="checkmark-icon"> {{ mdiCheckCircleOutline }}</v-icon>
              <div v-html="packageData['Marketing Text 1']"></div
            ></span>
          </v-row>
          <v-row v-if="packageData['Marketing Text 2']" class="marketing-text">
            <span class="description"
              ><v-icon :color="marketingTextIconColor" class="checkmark-icon"> {{ mdiCheckCircleOutline }}</v-icon>
              <div v-html="packageData['Marketing Text 2']"></div
            ></span>
          </v-row>
          <v-row v-if="packageData['Marketing Text 3']" class="marketing-text">
            <span class="description"
              ><v-icon :color="marketingTextIconColor" class="checkmark-icon"> {{ mdiCheckCircleOutline }}</v-icon>
              <div v-html="packageData['Marketing Text 3']"></div
            ></span>
          </v-row>
          <v-row v-if="packageData['Marketing Text 4']" class="marketing-text">
            <span class="description"
              ><v-icon :color="marketingTextIconColor" class="checkmark-icon"> {{ mdiCheckCircleOutline }}</v-icon>
              <div v-html="packageData['Marketing Text 4']"></div
            ></span>
          </v-row>
          <v-row v-if="packageData['Marketing Text 5']" class="marketing-text">
            <span class="description"
              ><v-icon :color="marketingTextIconColor" class="checkmark-icon"> {{ mdiCheckCircleOutline }}</v-icon>
              <div v-html="packageData['Marketing Text 5']"></div
            ></span>
          </v-row>
          <v-row v-if="packageData['Marketing Text 6']" class="marketing-text">
            <span class="description"
              ><v-icon :color="marketingTextIconColor" class="checkmark-icon"> {{ mdiCheckCircleOutline }}</v-icon>
              <div v-html="packageData['Marketing Text 6']"></div
            ></span>
          </v-row>
          <v-row v-if="packageData['Marketing Text 7']" class="marketing-text">
            <span class="description"
              ><v-icon :color="marketingTextIconColor" class="checkmark-icon"> {{ mdiCheckCircleOutline }}</v-icon>
              <span><div v-html="packageData['Marketing Text 7']"></div></span
            ></span>
          </v-row>
          <v-row v-if="packageData['Marketing Text 8']" class="marketing-text">
            <span class="description"
              ><v-icon :color="marketingTextIconColor" class="checkmark-icon"> {{ mdiCheckCircleOutline }}</v-icon>
              <div v-html="packageData['Marketing Text 8']"></div
            ></span>
          </v-row>
          <v-row v-if="packageData['Marketing Text 9']" class="marketing-text">
            <span class="description"
              ><v-icon :color="marketingTextIconColor" class="checkmark-icon"> {{ mdiCheckCircleOutline }}</v-icon>
              <div v-html="packageData['Marketing Text 9']"></div
            ></span>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="!showUpperOfferButton" align="end" justify="center">
        <v-btn
          class="button-margin"
          large
          dark
          :rounded="showRoundedOfferButton"
          elevation="5"
          v-on="!isOfferTileClickable ? { click: () => selectPackage(packageData) } : {}"
          :color="offerButtonColor"
          v-html="offerButtonText"
        >
        </v-btn>
      </v-row>
    </v-card>
  </v-card>
</div>
