<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12" class="pl-6">
        <v-row class="stepper-button-row">
          <v-btn
            id="back-button"
            :rounded="stepperButtonRoundedCorners"
            class="mr-3 stepper-back"
            color="default"
            @click="stepBack"
            v-if="showBackButton"
            >Back</v-btn
          >
          <div v-if="showCustomButton">
            <v-btn
              :rounded="stepperButtonRoundedCorners"
              :disabled="btnDisabled"
              :id="buttonId"
              class="pill mr-3 gtm-next-btn stepper-next"
              :class="defaultButtonColors()"
              :color="stepperButtonForwardColor"
              @click="stepForward"
            >
              {{ customButtonText }}
            </v-btn>
          </div>
          <div v-else>
            <v-btn
              :rounded="stepperButtonRoundedCorners"
              v-if="!isSubmitOrderStep && currentStep !== 2"
              :disabled="btnDisabled"
              :id="buttonId"
              class="pill mr-3 gtm-next-btn stepper-next"
              :class="defaultButtonColors()"
              :color="stepperButtonForwardColor"
              @click="stepForward"
              :elevation="stepperButtonElevation"
            >
              {{ customButtonText }}
            </v-btn>
            <v-btn
              :rounded="stepperButtonRoundedCorners"
              v-if="isSubmitOrderStep"
              :disabled="btnDisabled"
              :id="buttonId"
              class="pill stepper-submit"
              :class="defaultButtonColors()"
              :color="stepperButtonForwardColor"
              @click="submitOrder"
              :elevation="stepperButtonElevation"
            >
              {{ customButtonText }}
            </v-btn>
          </div>
          <v-btn
            v-if="thirdButtonText && thirdButtonNoWrap"
            :rounded="stepperButtonRoundedCorners"
            :disabled="btnDisabled"
            :id="'third-button'"
            class="pill mr-3 gtm-next-btn third-button"
            :class="defaultButtonColors()"
            :color="stepperButtonForwardColor"
            @click="thirdButtonAction"
          >
            {{ thirdButtonText }}
          </v-btn>
        </v-row>
        <v-row v-if="thirdButtonText && !thirdButtonNoWrap" justify="center" class="stprbtn-col pt-2">
          <v-btn
            :rounded="stepperButtonRoundedCorners"
            :disabled="btnDisabled"
            :id="'third-button'"
            class="pill mr-3 gtm-next-btn third-button"
            :class="defaultButtonColors()"
            :color="stepperButtonForwardColor"
            @click="thirdButtonAction"
          >
            {{ thirdButtonText }}
          </v-btn>
        </v-row>
        <v-row justify="center" v-if="showTermsOfService">
          <a class="pt-2" :href="termsOfServiceDocument.link" target="_blank">{{ termsOfServiceDocument.text }}</a>
          <Tooltip :payload="{ Name: termsOfServiceDocument.text }" />
        </v-row>
        <div v-for="(footerItem, index) in offerFooter" :key="index">
          <v-row id="offer-footer-row" justify="center" v-if="showOfferFooter(footerItem)">
            <a id="offer-footer-item" class="pt-2" :href="footerItem.link" target="_blank" v-html="footerItem.text"></a>
            <Tooltip :payload="{ Name: footerItem.name }" />
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import useTermsOfService from './useTermsOfService'
import useOrder from './useOrder'
import { computed, defineComponent } from '@vue/composition-api'
import { bus } from '@/main'
import useButtonColors from '@/components/shared/useButtonColors'
import Tooltip from '@/components/shared/tooltip/Tooltip.vue'
import $store from '@/store'
import { getConfigBoolean, getConfigItem, getConfigString } from '../shared/getConfigItem'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import { usePiniaRoute } from '@/store/pinia/piniaRoute'
import { IShopper } from '@adg/catalog/src/modules/Shopper'
import useCreditCheck from '@/components/shared/creditCheck/useCreditCheck'

export default defineComponent({
  name: 'StepperButtons',
  props: {
    forwardAction: Function,
    backAction: Function,
    buttonText: String,
    thirdButtonText: String,
    thirdButtonAction: Function,
    thirdButtonNoWrap: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Tooltip
  },
  setup(props, { root }) {
    const { $router } = root as any
    const { termsOfServiceDocument } = useTermsOfService()
    const { validation, currentStep, steps } = useOrder($store, $router)
    const { isInitialPreSale } = useCreditCheck()

    const btnDisabled = computed(() => props.disabled || !validation.value)
    const showCustomButton = props.forwardAction ? true : false
    const offerFooter = computed(() => getConfigItem(ConfigKeys.offerFooter))

    const stepperButtonRoundedCorners = computed(() => getConfigBoolean(ConfigKeys.stepperButtonRoundedCorners) ?? false)
    const stepperButtonForwardColor = computed(() => getConfigItem(ConfigKeys.stepperButtonForwardColor) ?? 'primary')
    const stepperButtonElevation = computed(() => getConfigItem(ConfigKeys.stepperButtonElevation) ?? '0')

    const stepForward = props.forwardAction ?? (() => bus.$emit('stepForward'))

    const presaleSubmitButtonText = computed(() => getConfigString(ConfigKeys.presaleSubmitButtonText) ?? 'Submit Order')
    const customButtonText = computed((): string => {
      if (props.buttonText) return props.buttonText
      if (currentStep.value === 4 && isInitialPreSale.value) return presaleSubmitButtonText.value
      return steps.value[currentStep.value - 1]?.forwardButtonLabel
    })

    const stepBack = props.backAction ?? (() => bus.$emit('stepBack'))
    const submitOrder = () => {
      bus.$emit('submitOrder')
    }
    const isSubmitOrderStep = computed(() =>
      currentStep.value ? steps.value[currentStep.value - 1]?.forwardButtonLabel === 'Submit Order' : false
    )

    const { defaultButtonColors } = useButtonColors()

    const showTermsOfService = computed((): boolean => {
      const currentRoute = usePiniaRoute().currentPath
      return (
        currentRoute === '/order/2' &&
        !!termsOfServiceDocument.value.link &&
        !!termsOfServiceDocument.value.text &&
        !getConfigItem(ConfigKeys.hideTvOptionsOnActiveAccountPage)
      )
    })

    const showOfferFooter = (footerItem: any): boolean => {
      const currentRoute = usePiniaRoute().currentPath
      return currentRoute === '/order/2' && footerItem.text
    }

    const isReturningPreSale = computed(() => $store.getters.getShopper.customInfo.returningPreSale)
    const buttonId = computed(() => {
      switch (currentStep.value) {
        case 1:
          return 'address-button'
          break
        case 2:
          return 'package-button'
          break
        case 3:
          return 'customize-button'
          break
        case 4:
          return 'account-button'
          break
        case 5:
          return 'schedule-button'
          break
        default:
          return 'default-button'
          break
      }
    })

    const showBackButton = computed(() => {
      if (currentStep.value < 2) return false
      if (currentStep.value === 5 && isReturningPreSale.value) return false
      return true
    })

    return {
      defaultButtonColors,
      btnDisabled,
      currentStep,
      steps,
      termsOfServiceDocument,
      stepForward,
      stepBack,
      submitOrder,
      isSubmitOrderStep,
      customButtonText,
      showCustomButton,
      buttonId,
      showTermsOfService,
      stepperButtonRoundedCorners,
      stepperButtonForwardColor,
      stepperButtonElevation,
      showBackButton,
      showOfferFooter,
      offerFooter
    }
  }
})
</script>

<style scoped>
.stepper-button-row {
  justify-content: center;
}
</style>
