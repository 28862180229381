import { IShopper } from '@adg/catalog/src/modules/Shopper'
import { getConfigString } from './../../shared/getConfigItem'
import {
  ChargeTypeKey,
  FeedItem,
  FeeGroup,
  ShoppingOrder,
  PricedItem,
  Product,
  Promo,
  Item,
  ConfigKeys,
  smartSum,
  getItemPriceAsNumber,
  getItemPrice,
  shouldPriceIntoParent,
  getItemFeesRecursive,
  CartTotal,
  getPromoProgressions,
  OrderPackage,
  newOrderPackage,
  isEquipment,
  isUpgrade,
  ShoppingCartPhone,
  isProduct,
  hasFees
} from '@adg/catalog/src/modules/Catalog'
import { computed, watch } from '@vue/composition-api'
import { flatten, has } from 'lodash'
import { coreCurrency } from '@adg/catalog/src/common/filters'
// import { GET_CATALOG } from '@/store/types'
// import useShopper from '../useShopper'
// import { GET_ALL_PRODUCT_ITEMS } from '@/store/modules/store'
import creditCheck from '@/components/shared/creditCheck/useCreditCheck'
import { Fee, Package } from '@adg/catalog/src/modules/Catalog'
import { GET_CURRENT_STEP } from '@/store/types'
import { Cart, CartPhone, StoreOrder } from '@adg/catalog/src/modules/Order'

export default ($store) => {
  const promo = computed(() => $store.getters.getPromo)
  const selectedPackage = computed(() => $store.getters.getPackage as Package)
  const order = computed(() => $store.getters.getOrder as StoreOrder)
  const shopper = computed(() => $store.getters.getShopper as IShopper)
  const cart = computed(() => $store.getters.getCart as Cart)
  const phone = computed(() => $store.getters.getPhone as CartPhone)
  const { hasBadCredit, mustPrePay } = creditCheck()
  const currentStep = computed(() => $store.getters[GET_CURRENT_STEP])

  // KWC now dynamically find all the Fee Groups defined in the Fees, instead of using what is in the catalog RefData
  //const feeGroups = computed(() => [...new Set(<string[]>allFees.value.map((f) => f['Fee Group']).filter((g) => g))])
  const feeGroups = computed(() =>
    allFees.value
      .map((f) => f['Fee Group'])
      .filter(exists)
      .filter(distinct)
  )

  const promoAvailable = computed(() => (selectedPackage?.value?.Promos ? selectedPackage.value.Promos.length > 0 : false))

  // const equipmentDisclaimers = computed(() => {
  //   const eD: any[] = []
  //   cart.value?.package?.Products?.forEach((product) => {
  //     if (product.Disclaimer) eD.push(product.Disclaimer)
  //   })
  //   cart.value?.package?.Fees?.forEach((fee) => {
  //     if (fee.Disclaimer) eD.push(fee.Disclaimer)
  //   })
  //   cart.value?.internet?.upgrades?.forEach((upgrade) => {
  //     if (upgrade.Disclaimer) eD.push(upgrade.Disclaimer)
  //   })
  //   cart.value?.phone?.upgrades?.forEach((upgrade) => {
  //     if (upgrade.Disclaimer) eD.push(upgrade.Disclaimer)
  //   })
  //   cart.value?.phone?.equipment?.forEach((equipment) => {
  //     if (equipment.Disclaimer) eD.push(equipment.Disclaimer)
  //   })
  //   cart.value?.tv?.equipment?.forEach((equipment) => {
  //     if (equipment.Disclaimer) eD.push(equipment.Disclaimer)
  //   })
  //   cart.value?.tv?.upgrades?.forEach((upgrade) => {
  //     if (upgrade.Disclaimer) eD.push(upgrade.Disclaimer)
  //   })
  //   cart.value?.packageUpgrades?.forEach((upgrade) => {
  //     if (upgrade.Disclaimer) eD.push(upgrade.Disclaimer)
  //   })

  //   return eD
  // })

  // todo: figure out type for value in distinct
  const distinct = (value, index: number, self: any[]) => self.indexOf(value) === index
  const exists = <T>(value: T): value is NonNullable<T> => value !== null && value !== undefined

  const getAllDisclaimers = computed(() => {
    const disclaimers = cartItems.value
      .map((cartItem: FeedItem) => {
        const cartItemWithFees: FeedItem[] = [cartItem]
        if (hasFees(cartItem)) {
          const fees = getItemFeesRecursive(cartItem, 1, true)
          fees.forEach((fee) => cartItemWithFees.push(fee))
        }
        return cartItemWithFees
      })
      .flat()
      .map((ci) => ci.Disclaimer)
      .filter(exists)
      .filter(distinct)
    return disclaimers
  })

  // const allProductFees = computed(() => {
  //   let productFees: Fee[] = []
  //   cart.value.products.forEach((product: Product) => {
  //     if (product.Fees) {
  //       productFees = [...productFees, ...product.Fees]
  //     }
  //   })
  //   return productFees
  // })

  const cartItems = computed(() => {
    let ret: FeedItem[] = flatten([
      selectedPackage.value ?? [],
      [...(selectedPackage.value.Products ?? [])],
      [...cart.value.tv.upgrades],
      [...cart.value.tv.equipment],
      [...cart.value.phone.equipment],
      [...cart.value.phone.upgrades],
      [...cart.value.internet.upgrades],
      [...cart.value.internet.equipment],
      [...cart.value.packageUpgrades],
      [...cart.value.homeAutomation.upgrades],
      [...cart.value.homeAutomation.equipment],
      cart.value.promo ?? []
    ])
    $store.commit('setCartItems', ret)
    return ret
  })

  const orderId = computed(() => (order.value ? order.value.id : 0))

  const schedule = computed(() => (cart.value ? cart.value.schedule : undefined))

  const allFees = computed((): Fee[] => {
    const fees = flatten(
      cartItems.value
        .filter((i) => notIncluded(i))
        .filter((i) => !shouldPriceIntoParent(i))
        .map((i) => getItemFeesRecursive(i, 1))
    )
    return fees
  })

  const allFeesEvenPricedIntoParent = computed((): Fee[] => {
    const fees = flatten(
      cartItems.value
        .filter((i) => i.itemType !== 'Package')
        .filter((i) => notIncluded(i))
        .map((i) => {
          return getItemFeesRecursive(i, 1, true)
        })
    )
    return fees
  })

  // const feesRecurring = computed(() => allFees.value)
  // const feesNonRecurring = computed(() => allFees.value)

  const itemsAndFees = computed(() => flatten([[...cartItems.value], [...allFees.value]]))

  function MinRank(a: Item, b: Item) {
    const r = a.Rank
  }

  const groupedEitherFees = (chargeType: ChargeTypeKey): FeeGroup[] => {
    let ret: FeeGroup[] = Array()
    let groups = feeGroups.value || []

    groups.forEach((fg) => {
      let g: FeeGroup = {
        Name: fg,
        itemType: 'FeeGroup',
        //Fees: allFees.value.filter((f) => f['Fee Group'] === fg).filter((f) => f[chargeType])
        Fees: allFees.value.filter((f) => f['Fee Group'] === fg)
      }
      g.FeeType = g.Fees[0].FeeType //all feetypes in a feegroup should be the same // we don't enforce this...
      g.expandable = g.Fees && g.Fees.find((f) => f.expandable) ? true : false

      g[chargeType] = g.Fees.map((f) => getItemPrice(f, chargeType)).reduce((a, b) => smartSum(a, b), 0)
      // Add group to the array if non-zero fees
      if (g[chargeType] && g[chargeType] != 0) {
        // set Rank to lowest rankVal of all Fees
        g.Rank = g.Fees.map((f) => rankval(f)).reduce((a, b) => (a < b ? a : b), Number.MAX_SAFE_INTEGER)
        ret.push(g)
      }
    })
    return ret
  }

  const groupedRecurringFees = computed(() => groupedEitherFees('Monthly Price'))
  const groupedNonRecurringFees = computed(() => groupedEitherFees('OTC'))

  const unGroupedEitherFees = (chargeType: ChargeTypeKey): Fee[] => {
    let ret: Fee[] = []
    let feegroups = feeGroups.value || []
    ret = allFees.value.filter((f) => !feegroups.includes(f['Fee Group'] || '')).filter((f) => getItemPrice(f, chargeType))
    return ret
  }

  const unGroupedRecurringFees = computed(() => unGroupedEitherFees('Monthly Price'))
  const unGroupedNonRecurringFees = computed(() => unGroupedEitherFees('OTC'))

  const isFeeType = (type: string): boolean => type === 'Fee' || type === 'FeeGroup'

  const monthlyNoFees = computed(() => {
    let nofees = monthlyCharges.value
      .filter((mc) => !isFeeType(mc.itemType))
      .filter((mc) => getItemPrice(mc, 'Monthly Price') !== undefined)
    return nofees
  })

  const monthlyFees = computed(() => {
    let fees = monthlyCharges.value.filter((mc) => isFeeType(mc.itemType)).filter((mc) => mc['Monthly Price'] !== undefined)
    return fees
  })

  const hasCharges = (item: FeedItem, chargeType: ChargeTypeKey): boolean => {
    if (shouldPriceIntoParent(item)) return false
    return item[chargeType] !== undefined || getItemPriceAsNumber(item, chargeType) != 0
  }
  const addRequiredPackage = (f: Fee, chargeType: ChargeTypeKey) => chargeType === 'Monthly Price' && f.itemType === 'Package' // always add packages to monthly fees, even if they have no price

  const EitherCharge = (chargeType: ChargeTypeKey): FeedItem[] => {
    let lineItems: FeedItem[] = []
    const charges = itemsAndFees.value.filter((u) => hasCharges(u, chargeType) || addRequiredPackage(u, chargeType)) as FeedItem[] // packages are always required for monthly fees
    for (let charge of charges) {
      if (charge.qty === undefined) {
        lineItems.push(charge)
      } else if (charge.qty > 0) {
        lineItems = [...lineItems, ...Array(charge.qty).fill({ ...charge, qty: 1 })]
      }
    }
    return lineItems
  }

  const isNumeric = (o: any): boolean => {
    return o !== undefined && typeof o === 'number' && !isNaN(o)
  }

  type ProrationFunction = () => number
  type ProrationFunctions = { [key: string]: ProrationFunction }
  type ProrationCondition = () => boolean
  type ProrationConditions = { [key: string]: ProrationCondition }

  const customProrationFunctions: ProrationFunctions = {
    MetronetProrationFee: function () {
      const mcs = monthlyCharges.value
      const proRation = mustPrePay.value ? -2 / 30 : 1 / 6
      return proRation * mcs.map((mc) => getItemPriceAsNumber(mc, 'Monthly Price')).reduce((a, b) => a + b, 0)
    }
  }

  const customProrationConditions: ProrationConditions = {
    requiresPayment: () => mustPrePay.value
  }

  const prorationCondition = (name?: string): boolean => {
    if (name && customProrationConditions[name]) return customProrationConditions[name]()
    return true
  }

  const prorationFees = computed((): Fee[] => {
    const prorationFunctionName = getConfigString(ConfigKeys.prorationFunction)
    const prorationConditionName = getConfigString(ConfigKeys.prorationCondition)
    const fees: Fee[] =
      prorationFunctionName && prorationCondition(prorationConditionName) && customProrationFunctions[prorationFunctionName]
        ? [
            {
              Name: getConfigString(ConfigKeys.prorationName) ?? 'Prorated Monthly Fee',
              itemType: 'Fee',
              OTC: customProrationFunctions[prorationFunctionName]()
            }
          ]
        : []
    return currentStep.value >= 5 ? fees : []
  })

  const rankval = (item: Item): number => {
    let rank = item.Rank
    const avgRank = Number.MAX_SAFE_INTEGER / 2
    if (typeof rank === 'undefined') {
      rank = avgRank
    } else if (typeof rank === 'number') {
    } else if (typeof rank === 'string') {
      rank = rank.match(/\s*/) ? avgRank : Number(rank)
      rank = isNaN(rank) ? avgRank : rank
    }
    if (rank < 0) {
      rank = Number.MAX_SAFE_INTEGER + rank
    }
    return rank
  }

  // return ret.sort((a, b) => (a.Name === 'Estimated Taxes' ? 1 : -1))
  const monthlyCharges = computed(() => {
    const ret = [
      ...EitherCharge('Monthly Price').filter((c) => !isFeeType(c.itemType)),
      ...unGroupedRecurringFees.value,
      ...groupedRecurringFees.value
    ]
    return ret.sort((a, b) => rankval(a) - rankval(b))
  })
  const oneTimeCharges = computed(() => {
    const ret = [
      ...EitherCharge('OTC').filter((c) => !isFeeType(c.itemType)),
      ...unGroupedNonRecurringFees.value,
      ...groupedNonRecurringFees.value,
      ...prorationFees.value
    ].filter((i) => notIncluded(i))
    //console.log('one time charges = ', ret)
    //return ret.sort((a, b) => rankval(a) - rankval(b)).filter((f) => f.OTC)
    return ret.sort((a, b) => rankval(a) - rankval(b))
  })

  const sumup = (sum: number, amnt: number): number => sum + amnt

  // const getNum = (s: string): number => {
  //   const num = Number(s)
  //   return isNaN(num) ? 0 : num
  // }

  const notIncluded = (item: FeedItem): boolean => {
    // KWC REALLY OLD VERSION
    // if (!product.included) return true
    // if (product.included === '1') {
    //   product['Monthly Price'] = 'Included' //handle includeds that didn't get set (Additional Services)
    // }
    // KWC END REALLY OLD VERSION

    // KWC OLD VERSION
    // if (item.priceIncluded) return false
    // if (item['Monthly Price'] !== 'Included') return true
    // const howManyInCart = monthlyCharges.value.filter((mc) => mc.Name === item.Name).length
    // return !item.included ? true : getNum(item.included) > howManyInCart
    // KWC END OLD VERSION

    // KWC NEW VERSION (hopefully correct)
    return !(item.priceIncluded || item['Monthly Price'] === 'Included')
  }

  const eitherTotal = (chargeType: ChargeTypeKey): number => {
    return EitherCharge(chargeType)
      .filter((ci) => ci && notIncluded(ci))
      .map((ci) => ci && getItemPriceAsNumber(ci, chargeType))
      .reduce(sumup, 0)
  }

  const monthlyTotal = computed(() =>
    monthlyCharges.value
      //.filter((ci) => ci && notIncluded(ci))
      .map((ci) => ci && getItemPriceAsNumber(ci, 'Monthly Price'))
      .reduce(sumup, 0)
  )

  const oneTimeTotal = computed(() =>
    oneTimeCharges.value
      //.filter((ci) => ci && notIncluded(ci))
      .map((ci) => ci && getItemPriceAsNumber(ci, 'OTC'))
      .reduce(sumup, 0)
  )

  // need to handle later month downpaymens
  const downPaymentFees = computed(() =>
    allFeesEvenPricedIntoParent.value.filter((f) => ['both', 'downpayment'].includes(f.FeeType ?? 'none'))
  )
  const reoccurringPaymentFees = computed(() =>
    allFeesEvenPricedIntoParent.value.filter((f) => ['both', 'reoccurring', 'recurring'].includes(f.FeeType ?? 'none'))
  )

  const downPaymentTotal = computed(() =>
    downPaymentFees.value
      .map((ci) => ci && getItemPriceAsNumber(ci, 'OTC') + getItemPriceAsNumber(ci, 'Monthly Price'))
      .reduce(sumup, 0)
  )
  const recurringTotal = computed(() =>
    reoccurringPaymentFees.value.map((ci) => ci && getItemPriceAsNumber(ci, 'Monthly Price')).reduce(sumup, 0)
  )

  //  const promoPrice = promo.value ? promo.value.Price : 0

  const grandTotal = computed((): number => {
    return monthlyTotal.value + oneTimeTotal.value // + promoPrice
  })

  const cartTotal = computed(() => {
    const total = {
      itemType: 'CartTotal',
      subItems: monthlyCharges.value.map((i) => {
        return { ...i, PriceIntoParent: true }
      }),
      Name: 'Monthly Total',
      expandable: false
    } as CartTotal
    return total
  })

  const removePromo = () => $store.commit('removePromo')

  const shoppingCart = computed(() => {
    const buildPhone = (): ShoppingCartPhone => {
      if (phone.value.Name === 'New') {
        return { ...phone.value.upgrades[0], phoneNumberDetails: phone.value.phoneNumberDetails }
      } else {
        return {
          ...phone.value.upgrades[0],
          phoneNumber: phone.value.phoneNumber,
          phoneNumberDetails: phone.value.phoneNumberDetails
        }
      }
    }

    const pkg = (): OrderPackage => {
      return selectedPackage.value
        ? {
            Name: selectedPackage.value.Name ?? '',
            'Display Name': selectedPackage.value['Display Name'] ?? '',
            Price: coreCurrency(getItemPrice(selectedPackage.value, 'Monthly Price')),
            'Product Types':
              selectedPackage.value.Products.map((p) => p['Product Type']).reduce(
                (a: string[], b: string | undefined): string[] => (b !== undefined ? a.concat(b) : a),
                []
              ) ?? [],
            'Billing Codes': selectedPackage.value['Billing Codes'],
            Disclaimer: selectedPackage.value['Disclaimer'],
            itemType: selectedPackage.value.itemType
          }
        : newOrderPackage()
    }

    // Need to format prices

    const products = selectedPackage.value['Products']?.map((p) => {
      const { Upgrades, Equipment, ...everythingElse } = p
      return { ...everythingElse }
    })

    // const equipment =

    const itemPrice = (item: FeedItem, key: ChargeTypeKey): string => {
      return item.priceIncluded ? 'Included' : coreCurrency(getItemPrice(item, key))
    }

    //    const orderFeeGroups = order.value.feeGroups;
    const order: ShoppingOrder = {
      id: orderId.value,
      feeGroups: feeGroups.value ?? [],
      schedule: $store.getters.getCartSchedule,
      items: cartItems.value.filter((i) => i.itemType !== 'Product'),
      package: pkg(),
      products: products,
      phone: phone.value && phone.value.upgrades.length > 0 ? buildPhone() : undefined,
      promo: promo.value ? { ...promo.value, Price: coreCurrency(promo.value['Price']) } : undefined,
      monthlyCharges: monthlyCharges.value
        .filter((mc) => mc.Name !== selectedPackage.value.Name)
        ?.map((mc) => ({
          ...mc,
          'Monthly Price': itemPrice(mc, 'Monthly Price')
        })),
      // monthlyNoFees: monthlyNoFees.value
      //   .filter((mc) => mc.Name !== selectedPackage.value.Name)
      //   ?.map((mc) => ({
      //     ...mc,
      //     'Monthly Price': itemPrice(mc, 'Monthly Price')
      //   })),
      // groupedRecurringFees: groupedRecurringFees.value
      //   .filter((mc) => mc.Name !== selectedPackage.value.Name)
      //   ?.map((mc) => ({
      //     ...mc,
      //     'Monthly Price': itemPrice(mc, 'Monthly Price')
      //   })),
      // unGroupedRecurringFees: unGroupedRecurringFees.value
      //   .filter((mc) => mc.Name !== selectedPackage.value.Name)
      //   ?.map((mc) => ({
      //     ...mc,
      //     'Monthly Price': itemPrice(mc, 'Monthly Price')
      //   })),
      oneTimeCharges: oneTimeCharges.value?.map((mc) => ({
        ...mc,
        OTC: itemPrice(mc, 'OTC')
      })),
      disclaimers: getAllDisclaimers.value,
      monthlyTotal: coreCurrency(monthlyTotal.value),
      grandTotal: coreCurrency(grandTotal.value),
      oneTimeTotal: coreCurrency(oneTimeTotal.value),
      promoProgressions: getPromoProgressions(cartTotal.value)
    }
    return order
  })

  const orderToSubmit = () => {
    const shopperId = $store.getters.getShopper.id
    return { order: shoppingCart.value, shopperId }
  }

  // const dependenciesMet = (product: Item) => {
  //   if (isEquipment(product) || isUpgrade(product)) {
  //     const dependencies = product.Dependencies ? product.Dependencies : []
  //     return dependencies.length === 0 ? true : dependencies.every(dependencyExists)
  //   } else {
  //     return false
  //   }
  // }

  // const dependencyExists = (dependency: string) => {
  //   const compareDependency = dependency
  //   const exists = cartItems.value
  //     ? cartItems.value.find((i) => i !== null && (i.Name === compareDependency || i['Product Type'] === compareDependency))
  //     : undefined
  //   return exists !== undefined
  // }

  const nullCheck = (v: Item) => v !== null
  const qtyCheck = (v: Item) => v.qty && v.qty > 0

  // const getNumericPrice = (item: PricedItem, priceType: ChargeTypeKey): number => {
  //   const n = getPrice(item, priceType)
  //   return n === undefined || typeof n === 'string' ? 0 : n
  // }

  const getPrice = (item: PricedItem, priceType: ChargeTypeKey): string | number | undefined => {
    return item.priceIncluded ? 'Included' : getItemPrice(item, priceType)
  }

  // const getPrice = (item: PricedItem, priceType: ChargeTypeKey): string | number | undefined => {
  //   if (item.priceIncluded) {
  //     return 'Included'
  //   }
  //   return item[priceType]
  //   // if (priceType == 'OTC') {
  //   //   return hasBadCredit.value && item['Bad Credit OTC'] ? item['Bad Credit OTC'] : item.OTC
  //   // }
  //   // return hasBadCredit.value && item['Bad Credit Monthly Price'] ? item['Bad Credit Monthly Price'] : item['Monthly Price']
  // }

  //const shoppingCart = computed(() => orderToSubmit().order)

  //watch(shoppingCart, (newval, oldval) => $store.commit('setShopperOrder', newval))

  return {
    promoAvailable,
    orderId,
    schedule,
    shopper,
    selectedPackage,
    promo,
    monthlyCharges,
    oneTimeCharges,
    monthlyTotal,
    oneTimeTotal,
    //fees: feesRecurring,
    //groupedRecurringFees,
    //unGroupedRecurringFees,
    //groupedNonRecurringFees,
    //unGroupedNonRecurringFees,
    //monthlyFees,
    monthlyNoFees,
    grandTotal,
    removePromo,
    //orderToSubmit,
    phone,
    //dependenciesMet,
    //dependencyExists,
    cartItems,
    itemsAndFees,
    downPaymentFees,
    reoccurringPaymentFees,
    // equipmentDisclaimers,
    getPrice,
    hasBadCredit,
    cartTotal,
    getAllDisclaimers,
    shoppingCart,
    downPaymentTotal,
    recurringTotal
  }
}
