<template>
  <div class="packages-container">
    <template v-for="(p, i) in filterPackages">
      <div :key="i" :class="packageComponent" class="pa-2" :id="lobString + (i + 1)">
        <PackageNew
          :packageData="p"
          :colorIndex="i"
          @packageSelected="packageSelected"
          :minWidth="225"
          :filterPackagesCount="filterPackageCount"
          :displayNameTextColor="displayNameTextColor(i)"
          :hasUnderPriceText="hasUnderPriceText"
        ></PackageNew>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from '@vue/composition-api'
import { getConfigItem } from '@/components/shared/getConfigItem'
import Package from './Package.vue'
import PackageContained from './PackageContained.vue'
import PackageSquare from './PackageSquare.vue'
import PackageMet from './PackageMet.vue'
import PackageNew from './PackageNew.vue'
import BroadbandLabelBase from './BroadbandLabelBase.vue'
import useOrder from '@/components/order/useOrder'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import $store from '@/store'

export default defineComponent({
  name: 'PackageContainer',
  components: {
    Package,
    PackageContained,
    PackageSquare,
    PackageMet,
    PackageNew,
    BroadbandLabelBase
  },
  props: {
    filterPackages: Array,
    packageSelected: Function

    //    packageData: {
    //      Name: String,
    //      MonthlyPrice: String,
    //      Products: [{ name: String, speed: String }]
    //    }
  },
  setup(props, { emit, root }) {
    const { $router } = root as any
    const { currentStep } = useOrder($store, $router)

    const packageComponent = computed(() => getConfigItem(ConfigKeys.offerTile) ?? 'Package')

    const filterPackageCount = computed(() => props.filterPackages?.length)

    const hasUnderPriceText = computed(() => {
      return props.filterPackages?.some((filterPackage: any) => {
        return filterPackage['Under Price Text']
      })
    })

    const displayNameTextColor = (i) => {
      if (i % 4 === 0) {
        return getConfigItem(ConfigKeys.firstDisplayNameColor) ?? ''
      }
      if (i % 4 === 1) {
        return getConfigItem(ConfigKeys.secondDisplayNameColor) ?? ''
      }
      if (i % 4 === 2) {
        return getConfigItem(ConfigKeys.thirdDisplayNameColor) ?? ''
      }
      if (i % 4 === 3) {
        return getConfigItem(ConfigKeys.fourthDisplayNameColor) ?? ''
      }
    }

    const lobString = computed(() => {
      let filterPackage
      if (props.filterPackages) {
        filterPackage = props.filterPackages[0]
      }

      let lobString = ''

      const lobs = filterPackage?.lobs

      if (lobs.includes('Internet')) {
        lobString += 'Internet'
      }

      if (lobs.includes('TV')) {
        lobString += 'TV'
      }

      if (lobs.includes('Latino TV')) {
        lobString += 'LatinoTV'
      }

      if (lobs.includes('Phone')) {
        lobString += 'Phone'
      }

      return lobString.replaceAll(/,/g, '')
    })

    return {
      packageComponent,
      currentStep,
      filterPackageCount,
      lobString,
      displayNameTextColor,
      hasUnderPriceText
    }
  }
})
</script>

<style scoped>
.packages-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.Package {
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 0px;
  max-width: 390px;
}
.PackageContained {
  flex-shrink: 1;
  flex-grow: 0;
  flex-basis: 0px;
}
.PackageSquare {
  flex-shrink: 1;
  flex-grow: 0;
  flex-basis: 0px;
}
</style>
